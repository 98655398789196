import axios from 'axios';
import { stringify } from 'qs';
import { call, put } from 'redux-saga/effects';

import { API_URL } from '../constants/APi';
import { APP_CONSTANTS } from '../constants/global';
import { authActions } from '../sagas/auth.saga';

const getParamsString = (params = {}) => {
  const encodedParams = stringify(params, { encodeValuesOnly: true, skipNulls: true });
  return encodedParams ? `?${encodedParams}` : '';
};

const HttpMethods = ['get', 'post', 'put', 'patch', 'delete'] as const;
export type IHttpMethod = typeof HttpMethods[number];

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  Pragma: 'no-cache',
  Expires: '0',
  Accept: 'application/json',
};

interface IRequestOptions {
  isAuth?: boolean;
  isFormData?: boolean;
}

const getHeaders = (requestOptions: IRequestOptions = {}) => {
  const headers = { ...defaultHeaders };
  if (requestOptions.isAuth) {
    const token = localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_NAME);
    if (!token) {
      throw new Error('401'); // todo
    }
    // @ts-ignore
    headers.Authorization = token;
  }
  if (requestOptions.isFormData) {
    headers['Content-Type'] = 'multipart/form-data';
  }
  return headers;
};

const getResourceUrl = (resource: string, params = {}, id: string | null) => {
  let paramsString = params;
  if ( typeof params === 'object' ) {
    paramsString = getParamsString(params);
  }
  const url = API_URL + resource;
  return id ? `${url}${id}${paramsString}/` : `${url}${paramsString}`;
};

// todo: handle no internet (! .status)
function* apiRequestGenerator(config: object) {
  try {
    // @ts-ignore
    const response = yield call(axios, config);

    return response.data;
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(authActions.logOut.update());
    } else {
      throw err.response || err;
    }
  }
}

const apiRequestPromise = (config: object) => {
  return axios(config)
    .then(response => response)
    .catch(err => {
      throw err.response || err;
    });
};

const getRequestConfig = (
  method: IHttpMethod,
  resource: string,
  dataOrID: object | string,
  urlParams = {},
  requestOptions?: IRequestOptions
) => {
  let id;
  let data = {};
  if (typeof dataOrID === 'string') {
    id = dataOrID;
  } else {
    data = dataOrID;
  }
  const url = getResourceUrl(resource, urlParams, id);

  return {
    method,
    url,
    headers: getHeaders(requestOptions),
    data,
  };
};

const createRequestGenerator = (method: IHttpMethod) =>
  function*(
    resource: string,
    dataOrID: object | string = {},
    requestOptions: IRequestOptions = { isAuth: true },
    urlParams = {}
  ) {
    return yield apiRequestGenerator(getRequestConfig(method, resource, dataOrID, urlParams, requestOptions));
  };

const createRequestPromise = (method: IHttpMethod) => (
  resource: string,
  dataOrID: object | string = {},
  requestOptions: IRequestOptions = { isAuth: true },
  urlParams = {}
) => apiRequestPromise(getRequestConfig(method, resource, dataOrID, urlParams, requestOptions));

export interface IRequests {
  [key: string]: (
    resource: string,
    dataOrID?: object | string,
    requestOptions?: IRequestOptions,
    urlParams?: object | string
  ) => any;
}

// returns a generator for each method
export const http: IRequests = HttpMethods.reduce(
  (result, method) => ({
    ...result,
    [method]: createRequestGenerator(method),
  }),
  {}
);

// non-generator version, returns a promise for each method
export const httpPromise: IRequests = HttpMethods.reduce(
  (result, method) => ({
    ...result,
    [method]: createRequestPromise(method),
  }),
  {}
);
