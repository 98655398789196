export const ROUTE_ACCESS = {
  PUBLIC: 0,
  PROTECTED: 1,
  ADMIN: 3,
};

export const ROUTES = {
  Root: {
    path: '/',
    access: ROUTE_ACCESS.PROTECTED,
    exact: true,
  },
  Login: {
    path: '/login',
    access: ROUTE_ACCESS.PUBLIC,
    exact: true,
  },
  ForgotPassStepEmail: {
    path: '/forgot-password',
    access: ROUTE_ACCESS.PUBLIC,
    exact: true,
  },
  ForgotPassStepReset: {
    path: '/reset-password',
    access: ROUTE_ACCESS.PUBLIC,
    exact: true,
  },
  Analytics: {
    path: '/analytics',
    access: ROUTE_ACCESS.PROTECTED,
    exact: true,
  },
  Rating: {
    path: '/rating',
    access: ROUTE_ACCESS.PROTECTED,
    exact: true,
  },
  Admin: {
    path: '/admin',
    access: ROUTE_ACCESS.ADMIN,
    exact: true,
  },
  Settings: {
    path: '/settings',
    access: ROUTE_ACCESS.PROTECTED,
    exact: true,
  },
  CompanyEditor: {
    path: '/edit-company',
    access: ROUTE_ACCESS.PROTECTED,
    exact: true,
  },
  UserEditor: {
    path: '/edit-user',
    access: ROUTE_ACCESS.PROTECTED,
    exact: true,
  },
  NoMatch: {
    path: '*',
    access: ROUTE_ACCESS.PUBLIC,
  },
};
