import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { Dispatch } from 'redux';

import { PasswordForm } from './Forms/PasswordForm';
import { GeneralForm } from './Forms/GeneralForm';

import moment from '../../utils/moment';

import {
  SETTINGS_GENERAL_VALUES,
  SETTINGS_PASSWORD_VALUES
} from '../../constants/formsInitialValues';
import {
  SETTINGS_GENERAL_SCHEMA,
  SETTINGS_PASSWORD_SCHEMA
} from '../../constants/formsYupSchemas';
import { REPORT_SCHEDULE_OPTIONS } from '../../constants/select';

import { isReady } from '../../utils/valueState';

import { IStore } from '../../types/IStore';
import {
  IProfile,
  IProfileUpdatePayload,
  IProfilePasswordUpdatePayload
} from '../../types/IProfile';
import { IOption } from '../../types/ISelect';

import { profileActions } from '../../sagas/profile.saga';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './Settings.module.scss';

interface IPropsFromStore extends Partial<IStore> {
  profile: IProfile;
}

interface IDispatchProps {
  updateProfile: (payload: IProfileUpdatePayload) => void;
  updateProfileImg: (payload: File) => void;
  updatePassword: (payload: IProfilePasswordUpdatePayload) => void;
  deleteProfileImage: () => void;
}

interface IState {
  initValues: { [key: string]: string | boolean | Date };
  reportSchedule: IOption;
}

const mapStateToProps = ({ profile }: IStore): IPropsFromStore => ({
  profile
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  updateProfile: (payload: IProfileUpdatePayload) =>
    dispatch(profileActions.profile.update(payload)),
  updateProfileImg: (payload: File) =>
    dispatch(profileActions.image.update(payload)),
  updatePassword: (payload: IProfilePasswordUpdatePayload) =>
    dispatch(profileActions.password.update(payload)),
  deleteProfileImage: () => dispatch(profileActions.deleteImage.update())
});

type IProps = IPropsFromStore & IDispatchProps;

class Settings extends Component<IProps, IState> {
  public state: Readonly<IState> = {
    initValues: SETTINGS_GENERAL_VALUES,
    reportSchedule: REPORT_SCHEDULE_OPTIONS[1],
  };
  private imgFile: File | null | undefined;

  public componentDidMount(): void {
    if (isReady(this.props.profile)) {
      this.getInitialValues();
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      isReady(this.props.profile) &&
      prevProps.profile._id !== this.props.profile._id
    ) {
      this.getInitialValues();
    }
  }

  private getInitialValues = () => {
    const { profile } = this.props;
    const reportScheduleOption = REPORT_SCHEDULE_OPTIONS.find( ({ value }) => value === profile.receiveReportEvery);

    this.setState({
      initValues: Object.keys(this.state.initValues).reduce(
        (values, key) => ({
          ...values,
          [key]:
            key === 'company'
              ? profile[key]?.name || ''
              : profile[key] !== undefined
                ? profile[key]
                : ''
        }),
        {}
      ),
      reportSchedule: reportScheduleOption || REPORT_SCHEDULE_OPTIONS[1],
    });
  };

  private onGeneralSubmit = (values, { setSubmitting }: FormikHelpers<any>) => {
    if ( values.dateOfBirth ) {
      values.dateOfBirth = moment(values.dateOfBirth).format('MM.DD.YYYY');
    }
    values.receiveReportEvery = this.state.reportSchedule.value;

    this.props.updateProfile(values);

    if (this.imgFile instanceof File) {
      this.props.updateProfileImg(this.imgFile);
    } else if (this.imgFile === null) {
      this.props.deleteProfileImage();
    }

    setSubmitting(false);
  };

  private onPasswordSubmit = (
    { oldPassword, password },
    { setSubmitting }: FormikHelpers<any>
  ) => {
    this.props.updatePassword({
      oldPassword,
      password
    });
    setSubmitting(false);
  };

  private imgDropped = (imgFile: File | null) => (this.imgFile = imgFile);

  private reportScheduleChanged = (option: IOption) =>
    this.setState({
      reportSchedule: option,
    });

  public render() {
    const { initValues, reportSchedule } = this.state;
    const { profile } = this.props;

    return (
      <>
        <p className={styles.title}>General settings</p>
        <Formik
          initialValues={initValues}
          validationSchema={SETTINGS_GENERAL_SCHEMA}
          onSubmit={this.onGeneralSubmit}
          enableReinitialize={true}
        >
          {props =>
            isReady(profile) && (
              <GeneralForm
                profileImg={profile.profileImg}
                imgDropped={this.imgDropped}
                reportSchedule={reportSchedule}
                reportScheduleChanged={option => this.reportScheduleChanged(option)}
                {...props}
              />
            )
          }
        </Formik>
        <p className={styles.title}>Change password</p>
        <Formik
          initialValues={SETTINGS_PASSWORD_VALUES}
          validationSchema={SETTINGS_PASSWORD_SCHEMA}
          onSubmit={this.onPasswordSubmit}
        >
          <PasswordForm />
        </Formik>
      </>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Settings);
