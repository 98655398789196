import { IStore } from '../types/IStore';
import { IProfile } from '../types/IProfile';
import { IAggregatedData, IRecentReports, IReport, IReportData, IReportsGroupByDate } from '../types/IReport';
import { IUser } from '../types/IUser';
import { ICompany } from '../types/ICompany';
import { IRating } from '../types/IRating';
import { INotification } from '../types/INotification';

import { APP_CONSTANTS } from '../constants/global';

import { createNotLoadedState } from './valueState';

export const getInitStore = (): IStore => {
  const token = localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_NAME);
  return {
    reportActive: false,
    auth: token,
    profile: (createNotLoadedState() as unknown) as IProfile,
    recentReports: (createNotLoadedState() as unknown) as IRecentReports,
    activeReport: false,
    reports: (createNotLoadedState() as unknown) as IReport[],
    groupedReports: (createNotLoadedState() as unknown) as IReportsGroupByDate[],
    pagination: {
      activePage: 1,
      pages: 1,
      nextPage: null,
      prevPage: null,
    },
    totals: (createNotLoadedState() as unknown) as IReportData,
    filters: null,
    users: (createNotLoadedState() as unknown) as IUser[],
    companies: (createNotLoadedState() as unknown) as ICompany[],
    aggregatedData: (createNotLoadedState() as unknown) as (IReportData & IAggregatedData)[],
    tooltip: null,
    selectedCompany: null,
    selectedUser: null,
    ratings: (createNotLoadedState() as unknown) as IRating[],
    notifications: (createNotLoadedState() as unknown) as INotification[],
    popups: {
      notifications: false,
    }
  }
};
