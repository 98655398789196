import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { ICompany, ICompanyCreatePayload, ICompanyLogoUpdatePayload, ICompanyUpdatePayload, ICompanyDeletePayload } from '../types/ICompany';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { isReady } from '../utils/valueState';
import { getStore, history } from '../store/store';
import {usersActions} from './users.saga';

const resource = 'COMPANIES';
const actions = ['get', 'create', 'update', 'delete', 'logo', 'deleteLogo'];
type reducerType = ICompany;

export const companiesTypes = createActionTypes(resource, actions);
export const companiesActions = createActions<reducerType>(resource, actions);
export const companiesReducer = createReducers<reducerType>(resource, actions);

function* getCompanies() {
  try {
    const response = yield call(http.get, API_ENDPOINTS.COMPANIES);
    yield put(companiesActions.get.success(response));
  } catch (err) {
    yield put(companiesActions.get.fail(err));
  }
}

function* createCompany({payload}: ICompanyCreatePayload) {
  try {
    const response = yield call(http.post, API_ENDPOINTS.COMPANIES, payload);
    const companies = getStore().companies;

    if ( payload.file ) {
      yield put(companiesActions.logo.update({
        file: payload.file,
        _id: response._id,
      }));
    }

    if ( isReady(companies) ) {
      companies.push(response);

      yield put(companiesActions.create.success(companies));
      history.goBack();
    } else {
      history.goBack();
    }

  } catch (err) {
    yield put(companiesActions.create.fail(err));
  }
}

function* updateCompany({payload}: ICompanyUpdatePayload) {
  try {
    yield call(http.patch, API_ENDPOINTS.COMPANIES, payload);
    const companies = getStore().companies;
    const company = companies.find( company => company._id === payload._id);

    if ( company ) {
      company.name = payload.name;
    }

    yield put(companiesActions.update.success(companies));
    history.goBack();
  } catch (err) {
    yield put(companiesActions.update.fail(err));
  }
}

function* deleteCompany({payload}: ICompanyDeletePayload) {
  try {
    yield call(http.delete, API_ENDPOINTS.COMPANIES, payload);
    const { companies, users } = getStore();
    const companiesFiltered = companies.filter( ({ _id }) => _id !== payload._id);
    const notCompanyUsers = users.filter( ({ company }) => company !== payload._id);

    yield put(companiesActions.delete.success(companiesFiltered));
    yield put(usersActions.get.success(notCompanyUsers));
    history.goBack();
  } catch (err) {
    yield put(companiesActions.delete.fail(err));
  }
}

function* updateCompanyLogo({payload}: ICompanyLogoUpdatePayload) {
  try {
    const { file, _id } = payload;

    const formData = new FormData();
    formData.append('img', file);

    const response = yield call(http.patch, API_ENDPOINTS.COMPANY_LOGO, formData, { isAuth: true, isFormData: true }, `?_id=${_id}`);
    const companies = getStore().companies;

    const company = companies.find( company => company._id === _id);

    if ( company ) {
      company.logo = response;
      yield put(companiesActions.logo.success(companies));
    }
  } catch (err) {
    yield put(companiesActions.logo.fail(err));
  }
}

function* deleteCompanyLogo({ payload }: {[payload: string]: string}) {
  try {
    yield call(http.delete, API_ENDPOINTS.COMPANY_LOGO, {_id: payload});
    const companies = getStore().companies;

    const company = companies.find( company => company._id === payload);

    if ( company ) {
      company.logo = null;
      yield put(companiesActions.logo.success(companies));
    }
  } catch (err) {
    yield put(companiesActions.deleteLogo.fail(err));
  }
}

export function* companiesSaga() {
  yield takeEvery(companiesTypes.get.load, getCompanies);
  yield takeEvery(companiesTypes.create.update, createCompany);
  yield takeEvery(companiesTypes.delete.update, deleteCompany);
  yield takeEvery(companiesTypes.logo.update, updateCompanyLogo);
  yield takeEvery(companiesTypes.deleteLogo.update, deleteCompanyLogo);
  yield takeEvery(companiesTypes.update.update, updateCompany);
}
