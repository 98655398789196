export const APP_CONSTANTS = {
  LOCAL_STORAGE_TOKEN_NAME: btoa('u-tok'),
  ACCESS_LEVELS: {
    EMPLOYEE: 0,
    ADMIN: 1,
  },
  TIME_ZONE: 'America/Chicago',
  MAX_RECENT_REPORTS_ON_DASHBOARD: 7,
  FIREBASE_STORAGE_TOKEN_NAME: btoa('f-tok'),
};
