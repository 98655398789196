import { AxiosResponse } from 'axios';
import { IReportData, IReport, IParsedReportsContent, IReportContent, IReportCalculation } from '../types/IReport';

import { REPORT_CONTENT, REPORT_CONTENT_ALIASES } from '../constants/report';
import { API_ENDPOINTS } from '../constants/APi';
import { AMOUNT_TIME, DAY_RATE, RATINGS_VALUES, MET_GOALS, RATINGS_STACK } from '../constants/rating';

import { formatReportTime } from './dates';
import { httpPromise } from './requests';
import { getStore } from '../store/store';
import { assignFiltersParams } from './filters.util';
import { IRatingRate, IRatingValue } from '../types/IRating';

export const parseReportsContent = (reports: IReport[]): IParsedReportsContent[] =>
  REPORT_CONTENT.reduce( (result: any[], {type}) => [
    ...result,
    {
      type,
      content: reports.map( ({createdAt, creator, ...rest}) => {
        return {
          value: rest[type],
          createdAt,
          creator
        }
      })
    }
  ], []);

export const sortByTime = (content: IReportContent[]): IReportContent[][] =>
  content.reduce((result, cont) => {
    result[formatReportTime(cont.createdAt)] = [
      ...(result[formatReportTime(cont.createdAt)] || []),
      cont
    ];
    return result;
  }, {}) as IReportContent[][];

export const sortByUser = (content: IReportContent[]): IReportContent[][] =>
  content.reduce((result, cont) => {
    result[cont.creator._id] = [
      ...(result[cont.creator._id] || []),
      cont
    ];
    return result;
  }, {}) as IReportContent[][];

export const getReportsTotals = (reports: IReport[]): IReportData =>
  REPORT_CONTENT.reduce((result, { type }) => ({
    ...result,
    [type]: reports.reduce((sum, report) => sum + (report[type] || 0), 0),
  }), {}) as IReportData;

export const getReportsCalculations = (totals: IReportData): IReportCalculation[] => {
  const { hoursKnocking, evaluations, insuranceLooks, projectsTurnedIn, totalColorMeetings, approvals, adjusterMeetings } = totals;

  return [
    {
      name: 'Minutes to generate 1 Eval?',
      value: hoursKnocking && evaluations ? Math.round((hoursKnocking / evaluations) * 60) : 0,
      unit: 'min',
    },
    {
      name: 'Closing %',
      value: insuranceLooks && evaluations ? Math.round((insuranceLooks / evaluations) * 100) : 0,
      unit: '%',
    },
    {
      name: 'Insurance looks no Adj mtg',
      value: insuranceLooks && adjusterMeetings ? Math.round(((insuranceLooks - adjusterMeetings) / insuranceLooks) * 100) : 0,
      unit: '%',
    },
    {
      name: 'Approval percentage',
      value: approvals && adjusterMeetings ? Math.round((approvals / adjusterMeetings) * 100) : 0,
      unit: '%',
    },
    {
      name: 'Color Mtg %',
      value: totalColorMeetings && approvals ? Math.round((totalColorMeetings / approvals) * 100) : 0,
      unit: '%',
    },
    {
      name: 'Color to Turned in %',
      value: projectsTurnedIn && totalColorMeetings ? Math.round((projectsTurnedIn / totalColorMeetings) * 100) : 0,
      unit: '%',
    },
    {
      name: 'Net Job %',
      value: projectsTurnedIn && approvals ? Math.round((projectsTurnedIn / approvals) * 100) : 0,
      unit: '%',
    },
  ]
};

export const combineByTimeReports = (content: IReport[]) =>
  content.reduce((result, cont) => {
    result[formatReportTime(cont.createdAt)] = [
      ...(result[formatReportTime(cont.createdAt)] || []),
      cont
    ];
    return result;
  }, {}) as IReportContent[][];

export const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

export const exportReport = (type: string, _id?: string): Promise<AxiosResponse> => {
  const { filters } = getStore();
  const defaultQuery = `?type=${type}${_id ? '&id=' + _id : ''}`;

  const query = filters ? assignFiltersParams(filters,  defaultQuery, true) : defaultQuery;

  return httpPromise.get(API_ENDPOINTS.EXPORT_REPORTS, undefined, undefined, query);
};

export const isValueDiff = (current: number, prev?: number): boolean => prev !== undefined && current !== prev;

export const getRate = (type: string, value: number): string => {
  if ( value === undefined ) {
    return '';
  }
  switch (type) {
    case 'metGoals':
      return value === +MET_GOALS.NO.value ? MET_GOALS.NO.name : MET_GOALS.YES.name;
    case 'dayRate':
      return DAY_RATE.find( rate => +rate.value === value)?.name || '';
    case 'amountTime':
      return AMOUNT_TIME.find( rate => +rate.value === value)?.name || '';
    default:
      return '';
  }
};

export const checkIsReportInActiveReport = (activeReport: boolean | IReport): boolean =>
  typeof activeReport === 'object' && !!activeReport._id;

export const getRatingValue = (value, alias: string): IRatingValue => {
  const items: IRatingRate[] = RATINGS_STACK[REPORT_CONTENT_ALIASES[alias]];
  const ratingNumber = items.find(({min, max}) => max !== null ? value >= min && value <= max : value >= min)?.value || 0;

  return RATINGS_VALUES.find( ({value}) => value === ratingNumber) || {
    value: 0,
    name: 'Not defined',
    color: '#0356A7',
  };
};
