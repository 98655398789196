import { authTypes } from '../sagas/auth.saga';
import { appReducer } from './appReducer';
import { getInitStore } from '../utils/initStore.util';

export const rootReducer = (store, action) => {
  switch (action.type) {
    case authTypes.logOut.success:
      store = getInitStore();
      break;
  }

  return appReducer(store, action);
};
