import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IUser } from '../../../../types/IUser';

import { APP_CONSTANTS } from '../../../../constants/global';

import { selectedUserActions } from '../../../../sagas/selectedUser.saga';
import { usersActions } from '../../../../sagas/users.saga';

import styles from './UsersTab.module.scss';

const getFullName = (firstName?: string, lastName?: string): string | null => {
  let fullName = '';

  if ( firstName ) {
    fullName += firstName;
  }

  if ( lastName ) {
    fullName += ' ' + lastName
  }

  return fullName.length ? fullName : null;
};

const getUserStatus = (accessLevel: number): string =>
  accessLevel === APP_CONSTANTS.ACCESS_LEVELS.EMPLOYEE ? 'salesperson' : 'admin';

interface IOuterProps {
  users: IUser[];
}

interface IDispatchProps {
  setSelectedUser: (payload: IUser) => void;
  changeActiveUser: (payload: string) => void;
}

type IProps = IOuterProps & IDispatchProps;

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  setSelectedUser: (payload: IUser) => dispatch(selectedUserActions.set.update(payload)),
  changeActiveUser: (payload: string) => dispatch(usersActions.changeActive.update(payload)),
});

class UsersTab extends Component<IProps> {

  private setUser = (user: IUser) => () => this.props.setSelectedUser(user);

  private handleUserActivation = (userId: string) => event => {
    event.stopPropagation();
    event.preventDefault();

    this.props.changeActiveUser(userId);
  }

  private renderUsers = () => this.props.users.map( user => (
    <div key={user._id} className={styles.tableRow} onClick={this.setUser(user)}>
      <p className={styles.tableItem}>{user.username || '--'}</p>
      <p className={styles.tableItem}>{getFullName(user.firstName, user.lastName) || '--'}</p>
      <p className={styles.tableItem}>{user.email}</p>
      <p className={styles.tableItem}>{getUserStatus(user.accessLevel)}</p>
      <div className={styles.tableItem}>
        {user.profileImg ? (
          <div className={styles.logo}>
            <img src={user.profileImg} alt={user.username || user.email} />
          </div>
        ) : (
          <p className={styles.noLogo}>
            {user.firstName
              ? user.firstName.charAt(0)
              : user.username
                ? user.username.charAt(0)
                : user.email.charAt(0)}
          </p>
        )}
      </div>
      <div className={styles.tableItem}>
        <label
          className={`${styles.status} ${user.isActive ? styles.active : styles.blocked}`}
          onClick={this.handleUserActivation(user._id)}
        >
          <input defaultChecked={user.isActive} type="checkbox" />
          {user.isActive ? 'Active' : 'Blocked'}
        </label>
      </div>
    </div>
  ));

  public render() {
    return (
      <div className={styles.table}>
        {this.props.users.length ? (
          <>
            <div className={`${styles.tableRow} ${styles.tableHeader}`}>
              <p className={styles.tableItem}>Username</p>
              <p className={styles.tableItem}>Name</p>
              <p className={styles.tableItem}>Email</p>
              <p className={styles.tableItem}>Access level</p>
              <p className={styles.tableItem}>Photo</p>
              <p className={styles.tableItem}>Status</p>
            </div>
            {this.renderUsers()}
          </>
        ) : <p className={styles.noContent}>No Users</p>}
      </div>
    )
  }
}

export default connect<void, IDispatchProps>(null, dispatchProps)(UsersTab);
