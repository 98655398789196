import { DAY_RATE_NAME, AMOUNT_TIME_NAME, MET_GOALS_NAME, MET_GOALS, FEELING_EXPLAIN } from './rating';
import { ACCESS_LEVEL_DEFAULT_OPTION } from './select';

export const LOGIN_FORM_VALUES = {
  email: '',
  password: '',
};

export const REPORT_FORM_VALUES = {
  doorsKnocked: '',
  hoursKnocking: '',
  evaluations: '',
  insuranceLooks: '',
  adjusterMeetings: '',
  approvals: '',
  totalColorMeetings: '',
  projectsTurnedIn: '',
  leadsActual: '',
  dollarsBooked: '',
  dollarsClosed: '',
  [DAY_RATE_NAME]: '',
  [AMOUNT_TIME_NAME]: '',
  [MET_GOALS_NAME]: MET_GOALS.YES.value,
  [FEELING_EXPLAIN]: '',
};

export const FORGOT_PASS_EMAIL_VALUES = {
  email: '',
};

export const FORGOT_PASS_RESET_VALUES = {
  password: '',
  rePassword: '',
};

export const SETTINGS_GENERAL_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  dateOfBirth: '',
  address: '',
  username: '',
  company: '',
  receiveReportEvery: '',
  isEmailNotif: true,
  isAppNotif: true,
};

export const SETTINGS_PASSWORD_VALUES = {
  oldPassword: '',
  password: '',
  rePassword: '',
};

export const COMPANY_EDITOR_VALUES = {
  companyName: '',
};

export const USER_EDITOR_VALUES = {
  email: '',
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  phone: '',
  company: '',
  accessLevel: ACCESS_LEVEL_DEFAULT_OPTION.value,
};
