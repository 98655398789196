import React, { Component } from 'react';
import Select from 'react-select';

import ChartPie from './Charts/ChartPie';
import ChartBar from './Charts/ChartBar';
import ChartLine from './Charts/ChartLine';
import ChartBarDashboard from './Charts/ChartBarDashboard';

import { IAggregatedData, IReport, IReportData } from '../../types/IReport';

import { CHART_SELECTOR_OPTIONS, CHART_DEFAULT_OPTION } from '../../constants/select';
import { CHART_TYPES } from '../../constants/chart.config';

import styles from './Chart.module.scss';

interface IProps {
  chartType: number;
  reports?: IReport[];
  aggregatedData?: (IReportData & IAggregatedData)[];
  selectorEnabled: boolean;
}

interface IState {
  selectedData: string;
  chartLegend: string;
}

const PIE_CHART_HIDDEN_EL_ALIAS = '--hidden';

class Chart extends Component<IProps> {
  public state: Readonly<IState> = {
    selectedData: CHART_DEFAULT_OPTION.value,
    chartLegend: '',
  };
  private chart: any = null;
  private legendRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
    if ( prevState.chartLegend !== this.state.chartLegend && this.legendRef.current ) {
      this.setLegendListeners();
    }
  }

  private handleChange = (e) =>
    this.setState({
      selectedData: e.value,
    });

  private onChartLegend = legend =>
    this.setState({
      chartLegend: legend,
    });

  private onLegendClick = (e, index: number) => {
    try {
      const { data } = this.chart;
      const { target } = e;

      if ( this.chart.config.type === 'pie' ) {
        const dataVal = data.datasets[0].data[index];

        data.datasets[0].data[index] = dataVal.toString().includes(PIE_CHART_HIDDEN_EL_ALIAS)
          ? parseFloat(dataVal.replace(PIE_CHART_HIDDEN_EL_ALIAS))
          : data.datasets[0].data[index] + PIE_CHART_HIDDEN_EL_ALIAS;

      } else {
        const isDataHidden = data.datasets[index].hidden;

        data.datasets[index].hidden = !isDataHidden;
      }

      target.classList.toggle('deactivated');

      this.chart.update();
    } catch (err) {
      console.error(err);
    }
  };

  private setLegendListeners = () => {
    const { current } = this.legendRef;

    current?.querySelectorAll('.chart-legend__item').forEach( (legendItem, index) =>
      legendItem.addEventListener('click', e => this.onLegendClick(e, index))
    );
  };

  private getChartByType = (type: number, dataType: string, reports?: IReport[], aggregatedData?: (IReportData & IAggregatedData)[]) => {
    switch (type) {
      case CHART_TYPES.PIE:
        return <ChartPie
          dataType={dataType}
          aggregatedData={aggregatedData as (IReportData & IAggregatedData)[]}
          onChartReady={chart => this.chart = chart}
          onChartLegend={this.onChartLegend}
        />;
      case CHART_TYPES.BAR:
        return <ChartBar
          dataType={dataType}
          aggregatedData={aggregatedData as (IReportData & IAggregatedData)[]}
          onChartReady={chart => this.chart = chart}
          onChartLegend={this.onChartLegend}
        />;
      case CHART_TYPES.LINE:
        return <ChartLine
          aggregatedData={aggregatedData as (IReportData & IAggregatedData)[]}
          onChartReady={chart => this.chart = chart}
          onChartLegend={this.onChartLegend}
        />;
      case CHART_TYPES.BAR_DASHBOARD:
        if ( reports ) {
          return <ChartBarDashboard
            dataType={dataType}
            reports={reports}
            onChartReady={chart => this.chart = chart}
            onChartLegend={this.onChartLegend}
          />;
        }
        return null;
    }
  };

  public render() {
    const { chartType, reports, aggregatedData, selectorEnabled } = this.props;
    const { selectedData } = this.state;

    return (
      <div className={`${styles.chartWrapper} ${selectorEnabled ? '' : styles.noSelector}`}>
        {selectorEnabled && (
          <div className={styles.header}>
            <p className={styles.headerText}>Show chart of</p>
            <Select
              defaultValue={CHART_DEFAULT_OPTION}
              onChange={this.handleChange}
              options={CHART_SELECTOR_OPTIONS}
              className="chart-selector"
              classNamePrefix="chart-selector"
              isSearchable={false}
            />
          </div>
        )}
        <div className={`${styles.chartWrap} ${chartType === CHART_TYPES.LINE ? styles.withoutShadow : ''}`}>
          {this.getChartByType(chartType, selectedData, reports, aggregatedData)}
          <div
            ref={this.legendRef}
            className={`${styles.chartLegend} ${chartType === CHART_TYPES.LINE ? styles.noScroll : ''}`}
            dangerouslySetInnerHTML={{__html: this.state.chartLegend}}
          />
        </div>

      </div>
    )
  }
}

export default Chart;
