import { combineReducers } from 'redux';

import { authReducer } from '../sagas/auth.saga';
import { profileReducer } from '../sagas/profile.saga';
import { recentReportsReducer } from '../sagas/recentReports.saga';
import { activeReportReducer } from '../sagas/activeReport.saga';
import { reportsReducer } from '../sagas/reports.saga';
import { totalsReducer } from '../sagas/totals.saga';
import { paginationReducer } from '../sagas/pagination.saga';
import { filtersReducer } from '../sagas/filters.saga';
import { usersReducer } from '../sagas/users.saga';
import { companiesReducer } from '../sagas/companies.saga';
import { aggregatedDataReducer } from '../sagas/aggregatedData.saga';
import { tooltipReducer } from '../reducers/tooltip';
import { selectedCompanyReducer } from '../sagas/selectedCompany.saga';
import { selectedUserReducer } from '../sagas/selectedUser.saga';
import { ratingsReducer } from '../sagas/ratings.saga';
import { notificationsReducer } from '../sagas/notifications.saga';
import { popupsReducer } from '../reducers/popups';
import { groupedReportsReducer } from '../sagas/groupedByDateReports.saga';

export const appReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  recentReports: recentReportsReducer,
  activeReport: activeReportReducer,
  reports: reportsReducer,
  pagination: paginationReducer,
  totals: totalsReducer,
  filters: filtersReducer,
  users: usersReducer,
  companies: companiesReducer,
  aggregatedData: aggregatedDataReducer,
  tooltip: tooltipReducer,
  selectedCompany: selectedCompanyReducer,
  selectedUser: selectedUserReducer,
  ratings: ratingsReducer,
  notifications: notificationsReducer,
  popups: popupsReducer,
  groupedReports: groupedReportsReducer,
});
