export const ERRORS = {
  EMAIL: {
    required: 'Email is required',
    incorrect: 'Email is incorrect'
  },
  PASSWORD: 'Password is required',
  REPEAT_PASSWORD: {
    required: 'Repeated password is required',
    match: 'Passwords do not match',
  },
  WRONG_LOGIN: {
    credentials: 'Unable to login with provided credentials.',
  },
  DOORS_KNOCKED: 'Doors knocked are required',
  HOURS_KNOCKING: {
    required: 'Hours knocking are required',
    max: 'Maximum value is 24',
  },
  EVALUATIONS: 'Evaluations are required',
  INSURANCE_LOOKS: 'Insurance looks are required',
  ADJUSTER_MEETINGS: 'Adjuster meetings are required',
  APPROVALS: 'Approvals are required',
  TOTAL_COLOR_MEETINGS: 'Total color meetings are required',
  PROJECTS_TURNED_IN: 'Projects turned in are required',
  NUMBER_LESS_THEN_MIN: 'Value must be greater than or equal to 0',
  DAY_RATE_REQUIRED: 'Please rate you day',
  AMOUNT_TIME_REQUIRED: 'Please specify your amount time',
  COMPANY_EDITOR: {
    required: 'Company name is required',
  },
  ACCESS_LEVEL: 'Access level is required',
};
