import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { RatingAdmin } from './RatingAdmin/RatingAdmin';
import { RatingUser } from './RatingUser/RatingUser';

import { IProfile } from '../../types/IProfile';
import { IRating } from '../../types/IRating';
import { IStore } from '../../types/IStore';

import { ratingsActions } from '../../sagas/ratings.saga';

import { didNotStartLoading, isReady } from '../../utils/valueState';
import { isAdmin } from '../../utils/user.util';
import {REPORT_CONTENT} from '../../constants/report';

interface IPropsFromStore {
  profile: IProfile;
  ratings: IRating[];
}

interface IDispatchProps {
  getRatings: () => void;
}

const mapStateToProps = ({ profile, ratings }: IStore): IPropsFromStore => ({
  profile,
  ratings,
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  getRatings: () => dispatch(ratingsActions.get.load()),
});

type IProps = IPropsFromStore & IDispatchProps;

class Rating extends Component<IProps> {
  private reportContent = REPORT_CONTENT.reduce((total, content) => ({
    ...total,
    [content.type]: content.name
  }), {});

  public componentDidMount(): void {
    if ( didNotStartLoading(this.props.ratings) ) {
      this.props.getRatings();
    }
  }

  public render() {
    const { profile, ratings } = this.props;

    return (
      <div>
        {isReady(ratings)
          ? isAdmin(profile)
            ? <RatingAdmin reportContent={this.reportContent} ratings={ratings} />
            : <RatingUser reportContent={this.reportContent} rating={ratings[0]} />
          : null}
      </div>
    )
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Rating);
