import { takeEvery, put } from 'redux-saga/effects';

import { IUser } from '../types/IUser';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';

import { history } from '../store/store';

import { ROUTES } from '../constants/router';

const resource = 'SELECTED_USER';
const actions = ['set', 'clear'];
type reducerType = IUser;

export const selectedUserTypes = createActionTypes(resource, actions);
export const selectedUserActions = createActions<reducerType>(resource, actions);
export const selectedUserReducer = createReducers<reducerType>(resource, actions);

function* setUser({payload}: {[payload: string]: IUser}) {
  yield put(selectedUserActions.set.success(payload));
  history.push(ROUTES.UserEditor.path);
}

function* clearUser() {
  yield put(selectedUserActions.clear.success(null))
}

export function* selectedUserSaga() {
  yield takeEvery(selectedUserTypes.set.update, setUser);
  yield takeEvery(selectedUserTypes.clear.update, clearUser);
}
