import React, { useState } from 'react';

import { IParsedReportsContent, IReportContent } from '../../../types/IReport';

import { REPORT_CONTENT } from '../../../constants/report';

import { sortByTime, sortByUser } from '../../../utils/report.util';
import moment from '../../../utils/moment';

import styles from './TableRecentReports.module.scss';

interface IProps {
  admin: boolean;
  content: IParsedReportsContent;
  dates: string[];
}

export const TableRecentReportsRow: React.FC<IProps> = ({
  admin,
  content,
  dates
}) => {
  const [expanded, setExpanded] = useState(false);
  const contentTypeName = REPORT_CONTENT.find(
    constContent => constContent.type === content.type
  );

  const contentByTime = Object.values(sortByTime(content.content));
  const contentByUser = Object.values(sortByUser(content.content));

  contentByUser.map((userCont: IReportContent[]) => {
    const emptyDatesIndexes: number[] = [];
    const firstItem = userCont[0];

    dates.forEach((date, index) => {
      const someInd = userCont.findIndex(cont =>
        moment.utc(cont.createdAt).isSame(moment.utc(date), 'day')
      );

      if (someInd < 0) {
        emptyDatesIndexes.push(index);
      }
    });
    emptyDatesIndexes.forEach(index => {
      userCont.splice(index, 0, {
        ...firstItem,
        createdAt: '',
        value: null
      });
    });
    return userCont.unshift(firstItem);
  });

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div className={`${styles.tableItem} ${expanded ? styles.active : ''}`}>
      <p className={styles.tableItemName}>{contentTypeName?.name}</p>
      {contentByTime.map((timeContent, index: number) => (
        <p key={index} className={styles.tableItemValue}>
          {timeContent.reduce((result, { value }) => result + (value || 0), 0)}
          {index + 1 === contentByTime.length && admin && (
            <span className={styles.tableItemActions}>
              <button
                onClick={toggleExpanded}
                className={`${styles.expand} ${expanded ? styles.active : ''}`}
              />
            </span>
          )}
        </p>
      ))}
      <p className={styles.tableItemValue}>
        {contentByTime.reduce(
          (total, timeContent) =>
            total +
            timeContent.reduce((result, { value }) => result + (value || 0), 0),
          0
        )}
      </p>
      {admin && (
        <>
          <div
            className={`${styles.hiddenTable} ${expanded ? styles.active : ''}`}
          >
            {contentByUser.map((userContent, index: number) => (
              <div key={index} className={styles.tableItem}>
                {userContent.map(({ creator, value }, ind) =>
                  ind > 0 ? (
                    <p key={ind} className={styles.tableItemValue}>
                      {value === undefined || value === null ? 0 : value}
                    </p>
                  ) : (
                    <p key={ind} className={styles.tableItemName}>
                      <span className={styles.tableItemNameContainer}>
                        {creator.username || creator.email}
                      </span>
                    </p>
                  )
                )}
                <p className={styles.tableItemValue}>
                  {userContent.reduce(
                    (total, { value }, ind) =>
                      ind > 0 ? total + (value || 0) : total + 0,
                    0
                  )}
                </p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
