import { takeEvery, call, put } from 'redux-saga/effects';

import { INotification } from '../types/INotification';

import { http } from '../utils/requests';

import { API_ENDPOINTS } from '../constants/APi';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';

const resource = 'NOTIFICATIONS';
const actions = ['get', 'confirm', 'reject', 'remove'];
type reducerType = INotification[];

export const notificationsTypes = createActionTypes(resource, actions);
export const notificationsActions = createActions<reducerType>(resource, actions);
export const notificationsReducer = createReducers<reducerType>(resource, actions);

function* getNotifications() {
  try {
    const response = yield call(http.get, API_ENDPOINTS.NOTIFICATIONS);

    yield put(notificationsActions.get.success(response));
  } catch (err) {
    yield put(notificationsActions.get.fail(err));
  }
}

function* confirmNotification({payload}: {payload: string}) {
  try {
    const response = yield call(http.patch, API_ENDPOINTS.NOTIFICATION_CONFIRM, {_id: payload});
    yield put(notificationsActions.confirm.success(response));
  } catch (err) {
    yield put(notificationsActions.confirm.fail(err));
  }
}

function* rejectNotification({payload}: {payload: string}) {
  try {
    const response = yield call(http.patch, API_ENDPOINTS.NOTIFICATION_REJECT, {_id: payload});
    yield put(notificationsActions.confirm.success(response));
  } catch (err) {
    yield put(notificationsActions.confirm.fail(err));
  }
}

function* removeNotification({payload}: {payload: string}) {
  try {
    const response = yield call(http.patch, API_ENDPOINTS.NOTIFICATION_REMOVE, {_id: payload});
    yield put(notificationsActions.confirm.success(response));
  } catch (err) {
    yield put(notificationsActions.confirm.fail(err));
  }
}

export function* notificationsSaga() {
  yield takeEvery(notificationsTypes.get.load, getNotifications);
  yield takeEvery(notificationsTypes.confirm.update, confirmNotification);
  yield takeEvery(notificationsTypes.reject.update, rejectNotification);
  yield takeEvery(notificationsTypes.remove.update, removeNotification);
}
