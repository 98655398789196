import React from 'react';

import { IParsedReportsContent, IReport } from '../../../types/IReport';

import { parseReportsContent } from '../../../utils/report.util';
import { formatReportTime } from '../../../utils/dates';

import { TableRecentReportsRow } from './TableRecentReportsRow';

import styles from './TableRecentReports.module.scss';

interface IProps {
  dates: string[];
  reports: IReport[];
  admin: boolean;
}

export const TableRecentReports: React.FC<IProps> = ({
  dates,
  reports,
  admin
}) => (
  <div className={styles.table}>
    {dates.length > 0 ? (
      <>
        <div className={styles.tableItem}>
          <p className={styles.tableItemName} />
          {dates.map(date => (
            <p key={date} className={styles.tableItemValue}>
              <span>{formatReportTime(date, true)}</span>
            </p>
          ))}
          <p className={styles.tableItemValue}>
            <span>Total</span>
          </p>
        </div>
        {parseReportsContent(reports).map((report: IParsedReportsContent) => (
          <TableRecentReportsRow
            key={report.type}
            dates={dates}
            content={report}
            admin={admin}
          />
        ))}
      </>
    ) : (
      <p className={styles.noData}>No reports yet</p>
    )}
  </div>
);
