import { takeEvery, put } from 'redux-saga/effects';

import { IPagination } from '../types/IPagination';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { isAdmin } from '../utils/user.util';

import { reportsActions } from './reports.saga';
import { groupedReportsActions } from './groupedByDateReports.saga';

import { getStore } from '../store/store';


const resource = 'PAGINATION';
const actions = ['set', 'activePage', 'reset'];
type reducerType = IPagination;

export const paginationTypes = createActionTypes(resource, actions);
export const paginationActions = createActions<reducerType>(resource, actions);
export const paginationReducer = createReducers<reducerType>(resource, actions);

function* setPagination({payload}: {[payload: string]: IPagination}) {
  yield put(paginationActions.set.success(payload));
}

function* setActivePage({payload}: {[payload: string]: number}) {
  yield put(paginationActions.activePage.success({
    activePage: payload,
  }));
  yield isAdmin(getStore().profile) ? put(groupedReportsActions.get.load()) : put(reportsActions.get.load());
}

function* reset() {
  yield put(paginationActions.reset.success({
    activePage: 1,
    pages: 1,
    prevPage: null,
    nextPage: null,
  }));
}

export function* paginationSaga() {
  yield takeEvery(paginationTypes.set.update, setPagination);
  yield takeEvery(paginationTypes.activePage.update, setActivePage);
  yield takeEvery(paginationTypes.reset.update, reset);
}
