import React from 'react';

import { IRating } from '../../../types/IRating';

import { AMOUNT_TIME, DAY_RATE, RATINGS_VALUES } from '../../../constants/rating';

import styles from './RatingUser.module.scss';

import { getRatingValue } from '../../../utils/report.util';

interface IProps {
  rating: IRating;
  reportContent: {[name: string]: string};
}

export const RatingUser: React.FC<IProps> = ({rating, reportContent}) => {
  if ( !rating ) {
    return (
      <div className={styles.rating}>
        <div className={styles.wrapper}>
          <p className={styles.noData}>We do not have rating yet</p>
        </div>
      </div>
    )
  }

  const dayRate = DAY_RATE.find( ({value}) => value === rating.rateDay.toString());
  const amountTime = AMOUNT_TIME.find( ({value}) => value === rating.amountTime.toString());
  const overallRating = RATINGS_VALUES.find( ({value}) => value === rating.ratingOverall);

  const renderReportRatingsParams = () => Object.keys(reportContent).map( alias => (
    <div key={alias + Date.now()} className={styles.sectionItem}>
      <p className={styles.rateTitle}>{reportContent[alias]}</p>
      <div className={styles.sectionRate}>
        <p
          className={styles.sectionRateValue}
          style={{color: getRatingValue(rating[alias], alias).color}}
        >
          {getRatingValue(rating[alias], alias).value}
        </p>
        <p
          className={styles.sectionRateName}
          style={{color: getRatingValue(rating[alias], alias).color}}
        >
          {getRatingValue(rating[alias], alias).name}
        </p>
      </div>
    </div>
  ));

  return (
    <div className={styles.rating}>
      <p className={styles.title}>My Rating</p>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p className={styles.rateTitle}>Overall Rating</p>
          <div className={styles.overall}>
            <p className={styles.overallName}>{overallRating?.name}</p>
            <div className={styles.overallRate}>
              <span className={`${styles.rateStar} ${rating.ratingOverall >= 1 ? styles.active : ''}`} />
              <span className={`${styles.rateStar} ${rating.ratingOverall >= 2 ? styles.active : ''}`} />
              <span className={`${styles.rateStar} ${rating.ratingOverall >= 3 ? styles.active : ''}`} />
              <span className={`${styles.rateStar} ${rating.ratingOverall >= 4 ? styles.active : ''}`} />
              <span className={`${styles.rateStar} ${rating.ratingOverall >= 5 ? styles.active : ''}`} />
            </div>
          </div>
        </div>
        <div className={styles.sections}>
          {renderReportRatingsParams()}
          <div className={styles.ratingSection}>
            <div className={styles.ratingSectionItem}>
              <p className={styles.rateTitle}>Average rating of the day</p>
              <p className={styles.ratingSectionValue} style={{color: dayRate?.color}}>{dayRate?.name}</p>
            </div>
            <div className={styles.ratingSectionItem}>
              <p className={styles.rateTitle}>Average time per day</p>
              <p className={styles.ratingSectionValue} style={{color: amountTime?.color}}>{amountTime?.name}</p>
            </div>
            <div className={styles.ratingSectionItem}>
              <p className={styles.rateTitle}>Today met your goals?</p>
              <p
                className={styles.ratingSectionValue}
                style={{
                  color: rating.metGoals >= rating.notMetGoals ? '#57ca20' : '#d61010',
                }}
              >
                More often {rating.metGoals >= rating.notMetGoals ? 'YES' : 'NO'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
