import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { IReport, IReportData, IReportsGroupByDate } from '../../../types/IReport';
import { IUser } from '../../../types/IUser';
import { IProfile } from '../../../types/IProfile';

import Pagination from './Pagination/Pagination';
import { UserRow } from './UserRow';

import { isReady } from '../../../utils/valueState';
import { isAdmin } from '../../../utils/user.util';
import { AdminRow } from './AdminRow';

import styles from './Table.module.scss';
import UserIcon from '../../../assets/imgs/user_icon.svg';

interface IProps {
  reports: IReport[];
  totals: IReportData;
  users: IUser[];
  onReportSelect: (report: IReport) => void;
  profile: IProfile;
  groupedReports: IReportsGroupByDate[];
}

export const Table: React.FC<IProps> = ({ reports, totals, users, onReportSelect, profile, groupedReports }) => {
  const [user, setUser] = useState<IUser | IProfile | null>(null);
  useEffect(() => {
    ReactTooltip.rebuild()
  });

  const onSelectReport = (report: IReport) => onReportSelect(report);

  const onMouseOverUser = (user: IUser | IProfile) => setUser(user);

  const renderReports = () =>
    reports.map((report: IReport | IReportsGroupByDate) => {

      return <UserRow
        key={report._id}
        users={users}
        profile={profile}
        report={report as IReport}
        onReportClick={onSelectReport}
        onUserMouseOver={onMouseOverUser}
      />;
    });

  const renderGroupedReports = () =>
    groupedReports.map(report => {
      return <AdminRow
        key={report.date}
        users={users}
        profile={profile}
        report={report}
        onReportClick={onSelectReport}
        onUserMouseOver={onMouseOverUser}
      />;
    });

  return (
    <div className={styles.table}>
      <div className={`${styles.row} ${styles.header}`}>
        <p className={`${styles.column} ${styles.headerName}`} />
        <p className={`${styles.column} ${styles.headerName}`}>Doors Knocked</p>
        <p className={`${styles.column} ${styles.headerName}`}>
          Hours Knocking
        </p>
        <p className={`${styles.column} ${styles.headerName}`}>
          Leads
          <br />
          Actual
        </p>
        <p className={`${styles.column} ${styles.headerName}`}>Evaluations</p>
        <p className={`${styles.column} ${styles.headerName}`}>
          Insurance Looks
        </p>
        <p className={`${styles.column} ${styles.headerName}`}>
          Adjuster Meetings
        </p>
        <p className={`${styles.column} ${styles.headerName}`}>Approvals</p>
        <p className={`${styles.column} ${styles.headerName}`}>
          Total Color Meetings
        </p>
        <p className={`${styles.column} ${styles.headerName}`}>
          Projects
          <br />
          Turned in
        </p>
        <p className={`${styles.column} ${styles.headerName}`}>
          Dollars
          <br />
          Booked
        </p>
        <p className={`${styles.column} ${styles.headerName}`}>
          Dollars
          <br />
          Closed
        </p>
      </div>
      {isReady(totals) && (
        <div className={`${styles.row} ${styles.total}`}>
          <p className={styles.column}>Total</p>
          <p className={styles.column}>{totals.doorsKnocked}</p>
          <p className={styles.column}>{totals.hoursKnocking}</p>
          <p className={styles.column}>{totals.leadsActual}</p>
          <p className={styles.column}>{totals.evaluations}</p>
          <p className={styles.column}>{totals.insuranceLooks}</p>
          <p className={styles.column}>{totals.adjusterMeetings}</p>
          <p className={styles.column}>{totals.approvals}</p>
          <p className={styles.column}>{totals.totalColorMeetings}</p>
          <p className={styles.column}>{totals.projectsTurnedIn}</p>
          <p className={styles.column}>{totals.dollarsBooked}</p>
          <p className={styles.column}>{totals.dollarsClosed}</p>
        </div>
      )}
      {isReady(profile) && !isAdmin(profile) && isReady(reports) && (
        <>
          <div className={styles.tableContent}>
            {renderReports()}
          </div>
          <Pagination />
        </>
      )}
      {isReady(profile) && isAdmin(profile) && isReady(groupedReports) && (
        <>
          <div className={styles.tableContent}>
            {renderGroupedReports()}
          </div>
          <Pagination />
        </>
      )}
      <ReactTooltip
        delayShow={300}
        type="light"
        className={styles.tooltip}
        effect="solid"
      >
        {user && (
          <div className={styles.userTooltip}>
            <div className={styles.userTooltipImg}>
              <img src={user.profileImg || UserIcon} alt={user.profileImg} />
            </div>
            {user.email && (
              <p className={styles.userTooltipInfo}>
                <span>Email:</span>
                <span>{user.email}</span>
              </p>
            )}
            {user.username && (
              <p className={styles.userTooltipInfo}>
                <span>Username:</span>
                <span>{user.username}</span>
              </p>
            )}
            {user.firstName && (
              <p className={styles.userTooltipInfo}>
                <span>First name:</span>
                <span>{user.firstName}</span>
              </p>
            )}
            {user.lastName && (
              <p className={styles.userTooltipInfo}>
                <span>Last name:</span>
                <span>{user.lastName}</span>
              </p>
            )}
          </div>
        )}
      </ReactTooltip>
    </div>
  );
};
