import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { IReport } from '../types/IReport';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getStore } from '../store/store';
import { assignFiltersParams } from '../utils/filters.util';
import { isReady } from '../utils/valueState';

import { paginationActions } from './pagination.saga';

const resource = 'REPORTS';
const actions = ['get', 'update', 'delete'];
type reducerType = IReport[];

export const reportsTypes = createActionTypes(resource, actions);
export const reportsActions = createActions<reducerType>(resource, actions);
export const reportsReducer = createReducers<reducerType>(resource, actions);

function* getReports() {
  try {
    const { pagination, filters } = getStore();

    const query = filters
      ? assignFiltersParams(filters, `?page=${pagination.activePage}`)
      : `?page=${pagination.activePage}`;

    const { reports, pages, prevPage, nextPage } = yield call(http.get, API_ENDPOINTS.REPORTS, undefined, undefined, query);

    yield put(reportsActions.get.success(reports));
    yield put(paginationActions.set.update({
      activePage: pagination.activePage,
      pages,
      prevPage,
      nextPage,
    }))
  } catch (err) {
    yield put(reportsActions.get.fail(err));
  }
}

function* updateReport({payload}: {payload: IReport}) {
  const { reports } = getStore();

  if ( isReady(reports) ) {
    const reportIndex = reports.findIndex( ({_id}) => _id === payload.reportID);

    const report = {
      ...reports[reportIndex],
      ...payload
    };

    reports.splice(reportIndex, 1, report);

    yield put(reportsActions.update.success(reports));
  }
}

function* deleteReport({payload}: {payload: string}) {
  const { reports } = getStore();

  if ( isReady(reports) ) {
    const reportIndex = reports.findIndex( ({_id}) => _id === payload);

    reports.splice(reportIndex, 1);

    yield put(reportsActions.delete.success(reports));
  }
}

export function* reportsSaga() {
  yield takeEvery(reportsTypes.get.load, getReports);
  yield takeEvery(reportsTypes.update.update, updateReport);
  yield takeEvery(reportsTypes.delete.update, deleteReport);
}
