import { all, fork } from 'redux-saga/effects';

import { authSaga } from '../sagas/auth.saga';
import { profileSaga } from '../sagas/profile.saga';
import { recentReportsSaga } from '../sagas/recentReports.saga';
import { activeReportSaga } from '../sagas/activeReport.saga';
import { reportsSaga } from '../sagas/reports.saga';
import { totalsSaga } from '../sagas/totals.saga';
import { paginationSaga } from '../sagas/pagination.saga';
import { filtersSaga } from '../sagas/filters.saga';
import { usersSaga } from '../sagas/users.saga';
import { companiesSaga } from '../sagas/companies.saga';
import { aggregatedDataSaga } from '../sagas/aggregatedData.saga';
import { selectedCompanySaga } from '../sagas/selectedCompany.saga';
import { selectedUserSaga } from '../sagas/selectedUser.saga';
import { ratingsSaga } from '../sagas/ratings.saga';
import { notificationsSaga } from '../sagas/notifications.saga';
import { groupedReportsSaga } from '../sagas/groupedByDateReports.saga';

const allSagas: any = [
  authSaga,
  profileSaga,
  recentReportsSaga,
  activeReportSaga,
  reportsSaga,
  totalsSaga,
  paginationSaga,
  filtersSaga,
  usersSaga,
  companiesSaga,
  aggregatedDataSaga,
  selectedCompanySaga,
  selectedUserSaga,
  ratingsSaga,
  notificationsSaga,
  groupedReportsSaga,
];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
