export const TABS = {
  USERS: {
    number: 1,
    name: 'users',
  },
  COMPANIES: {
    number: 0,
    name: 'companies',
  },
};

export const ADMIN_TIME_FORMAT = 'MM.DD.YYYY';
