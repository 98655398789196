import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';

import { httpPromise } from '../../utils/requests';

import { ROUTES } from '../../constants/router';
import { API_ENDPOINTS } from '../../constants/APi';
import { FORGOT_PASS_EMAIL_VALUES } from '../../constants/formsInitialValues';
import { FORGOT_PASS_EMAIL_SCHEMA } from '../../constants/formsYupSchemas';

import Logo from '../../assets/imgs/logo.svg';

import styles from './ForgotPass.module.scss';

type IProps = any;

interface IState {
  responseMessage: string;
}

class ForgotPass extends Component<IProps, IState> {
  public state: Readonly<IState> = {
    responseMessage: ''
  };

  private onFormSubmit = ({ email }, { setSubmitting }: FormikHelpers<any>) =>
    httpPromise
      .post(API_ENDPOINTS.FORGOT_PASS_STEP_EMAIL, { email }, { isAuth: false })
      .then(res =>
        this.setState(
          {
            responseMessage: res.data.message
          },
          () => {
            setSubmitting(false);
            toast.info(res.data.message);
          }
        )
      );

  public render() {
    const { responseMessage } = this.state;

    return (
      <div className={styles.notAuth}>
        <div className={styles.notAuthLogo}>
          <Link to={ROUTES.Root.path}>
            <img src={Logo} alt="Superstorm" />
          </Link>
        </div>
        <p className={styles.forgotPassNote}>
          Enter your email address and we will send you a link to reset your
          password
        </p>
        <Formik
          initialValues={FORGOT_PASS_EMAIL_VALUES}
          validationSchema={FORGOT_PASS_EMAIL_SCHEMA}
          onSubmit={this.onFormSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={styles.notAuthForm}>
              <div className={styles.notAuthFormField}>
                <Field type="email" name="email" placeholder="Email" />
                <ErrorMessage
                  name="email"
                  component="p"
                  className={styles.notAuthFormError}
                />
              </div>
              {!responseMessage && (
                <Link to={ROUTES.Root.path} className={styles.notAuthLink}>
                  Back
                </Link>
              )}
              {responseMessage && (
                <Link
                  className={`${styles.notAuthFormSubmit} ${styles.link}`}
                  to={ROUTES.Root.path}
                >
                  Login
                </Link>
              )}
              {!responseMessage && (
                <button
                  type="submit"
                  className={styles.notAuthFormSubmit}
                  disabled={isSubmitting}
                >
                  Send
                </button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default ForgotPass;
