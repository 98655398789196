import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { IReport, IRecentReports } from '../types/IReport';

import { createActionTypes, createActions, createReducers } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';

import { getStore } from '../store/store';
import {isReady} from '../utils/valueState';

const resource = 'RECENT_REPORTS';
const actions = ['get', 'update', 'delete'];
type reducerType = IRecentReports;

export const recentReportsTypes = createActionTypes(resource, actions);
export const recentReportsActions = createActions<reducerType>(resource, actions);
export const recentReportsReducer = createReducers<reducerType>(resource, actions);

function* getRecentReports() {
  try {
    const response: IReport[] = yield call(http.get, API_ENDPOINTS.RECENT_REPORTS);
    yield put(recentReportsActions.get.success(response));
  } catch (err) {
    yield put(recentReportsActions.get.fail(err));
  }
}

function* updateReport({payload}: {payload: IReport}) {
  const { recentReports } = getStore();

  if ( isReady(recentReports) ) {
    const reportIndex = recentReports.reports.findIndex( ({_id}) => _id === payload._id);

    if ( reportIndex >= 0 ) {
      const report = {
        ...recentReports.reports[reportIndex],
        ...payload
      };

      recentReports.reports.splice(reportIndex, 1, report);

      yield put(recentReportsActions.update.success(recentReports));
    }
  }
}

function* deleteReport({payload}: {payload: string}) {
  const { recentReports } = getStore();

  if ( isReady(recentReports) ) {
    const reportIndex = recentReports.reports.findIndex( ({_id}) => _id === payload);

    if ( reportIndex >= 0 ) {
      recentReports.reports.splice(reportIndex, 1);

      yield put(recentReportsActions.delete.success(recentReports));
    }
  }
}

export function* recentReportsSaga() {
  yield takeEvery(recentReportsTypes.get.load, getRecentReports);
  yield takeEvery(recentReportsTypes.update.update, updateReport);
  yield takeEvery(recentReportsTypes.delete.update, deleteReport);
}
