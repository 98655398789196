import React from 'react';
import { ErrorMessage, Field, Form } from 'formik';

import styles from '../Settings.module.scss';

export const PasswordForm: React.FC = () => (
  <Form className={styles.form}>
    <div className={styles.columns}>
      <div className={`${styles.column} ${styles.single}`}>
        <Field name="oldPassword">
          {({ field, meta }) => (
            <label
              className={`${styles.field} ${
                meta.touched && meta.error ? styles.fieldError : ''
              }`}
            >
              <p className={styles.fieldTitle}>Current password</p>
              <div className={styles.fieldBlog}>
                <input placeholder="**********" type="password" {...field} />
                <ErrorMessage
                  className={styles.fieldErrorMessage}
                  name="oldPassword"
                  component="p"
                />
              </div>
            </label>
          )}
        </Field>
        <Field name="password">
          {({ field, meta }) => (
            <label
              className={`${styles.field} ${
                meta.touched && meta.error ? styles.fieldError : ''
              }`}
            >
              <p className={styles.fieldTitle}>New password</p>
              <div className={styles.fieldBlog}>
                <input placeholder="**********" type="password" {...field} />
                <ErrorMessage
                  className={styles.fieldErrorMessage}
                  name="password"
                  component="p"
                />
              </div>
            </label>
          )}
        </Field>
        <Field name="rePassword">
          {({ field, meta }) => (
            <label
              className={`${styles.field} ${
                meta.touched && meta.error ? styles.fieldError : ''
              }`}
            >
              <p className={styles.fieldTitle}>Confirm password</p>
              <div className={styles.fieldBlog}>
                <input placeholder="**********" type="password" {...field} />
                <ErrorMessage
                  className={styles.fieldErrorMessage}
                  name="rePassword"
                  component="p"
                />
              </div>
            </label>
          )}
        </Field>
      </div>
    </div>
    <button type="submit">Change</button>
  </Form>
);
