import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import Select from 'react-select';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';

import { history } from '../../store/store';

import { USER_EDITOR_CREATE_SCHEMA, USER_EDITOR_EDIT_SCHEMA } from '../../constants/formsYupSchemas';
import { USER_EDITOR_VALUES } from '../../constants/formsInitialValues';
import { ACCESS_LEVEL_SELECTOR_OPTIONS, ACCESS_LEVEL_DEFAULT_OPTION } from '../../constants/select';
import { APP_CONSTANTS } from '../../constants/global';

import { IStore } from '../../types/IStore';
import { ICompany } from '../../types/ICompany';
import { IOption } from '../../types/ISelect';
import { IUser, IUserCreate } from '../../types/IUser';

import { companiesActions } from '../../sagas/companies.saga';
import { usersActions } from '../../sagas/users.saga';
import { selectedUserActions } from '../../sagas/selectedUser.saga';

import { isReady } from '../../utils/valueState';

import { CompanyConfirmation } from '../CompanyEditor/CompanyConfirmation/CompanyConfirmation';

import styles from './UserEditor.module.scss'

interface IPropsFromStore {
  companies: ICompany[];
  selectedUser: IUser | null;
}

interface IDispatchProps {
  getCompanies: () => void;
  createUser: (payload: IUserCreate) => void;
  updateUser: (payload: IUserCreate) => void;
  deleteUser: (payload: string) => void;
  clearSelectedUser: () => void;
}

interface IState {
  accessLevel: IOption;
  company: IOption | null;
  isPassShowing: boolean;
}

const mapStateToProps = ({ companies, selectedUser }: IStore): IPropsFromStore => ({
  companies,
  selectedUser,
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  getCompanies: () => dispatch(companiesActions.get.load()),
  createUser: (payload: IUserCreate) => dispatch(usersActions.create.update(payload)),
  updateUser: (payload: IUserCreate) => dispatch(usersActions.update.update(payload)),
  deleteUser: (payload: string) => dispatch(usersActions.delete.update(payload)),
  clearSelectedUser: () => dispatch(selectedUserActions.clear.update()),
});

type IProps = IPropsFromStore & IDispatchProps;

class UserEditor extends Component<IProps, IState> {
  public state: Readonly<IState> = {
    accessLevel: ACCESS_LEVEL_DEFAULT_OPTION,
    company: null,
    isPassShowing: false,
  };

  public componentDidMount(): void {
    const { selectedUser, companies } = this.props;

    if ( selectedUser ) {
      const accessLevelIndex = selectedUser.accessLevel === APP_CONSTANTS.ACCESS_LEVELS.EMPLOYEE ? 0 : 1;
      const company = companies.find( ({_id}) => _id === selectedUser.company);

      this.setState({
        accessLevel: ACCESS_LEVEL_SELECTOR_OPTIONS[accessLevelIndex],
        company: company ?
          {
            label: company.name,
            value: company._id
          } : null,
      });
    }
  }

  public componentWillUnmount(): void {
    this.props.clearSelectedUser();
  }

  private getInitialValues = () => {
    if ( this.props.selectedUser ) {

      return {
        ...USER_EDITOR_VALUES,
        ...this.props.selectedUser,
      }
    }
    return {
      ...USER_EDITOR_VALUES,
    };
  };

  private getSchemas = () => this.props.selectedUser ? USER_EDITOR_EDIT_SCHEMA : USER_EDITOR_CREATE_SCHEMA;

  private onCancel = () => history.goBack();

  private onDelete = () => toast(() => <CompanyConfirmation title={'Delete this user?'} deleteApproved={this.deleteUser} />);

  private deleteUser = () => {
    toast.dismiss();
    this.props.deleteUser(this.props.selectedUser?._id as string);
  };

  private onFormSubmit = (values, { setSubmitting }: FormikHelpers<any>) => {
    const { accessLevel, company } = this.state;
    const { selectedUser } = this.props;

    if ( selectedUser ) {
      this.props.updateUser({
        ...values,
        accessLevel: accessLevel.value,
        company: company?.value,
        _id: selectedUser._id,
      });
    } else {
      this.props.createUser({
        ...values,
        accessLevel: accessLevel.value,
        company: company?.value,
      });
    }

    setSubmitting(false);
  };

  private passToggle = () =>
    this.setState({
      isPassShowing: !this.state.isPassShowing,
    });

  private handleSelectChanged = (option, field: string) =>
    this.setState(
      // @ts-ignore
      {
        [field]: option,
      }
    );

  private renderCompanySelect = () => () => {
    const { companies } = this.props;
    let options: IOption[] = [];

    if ( isReady(companies) ) {
      options = companies.map( company => ({
          value: company._id,
          label: company.name,
      }));
    }

    return <Select
      defaultValue={this.state.company}
      options={options}
      className="settings-selector"
      classNamePrefix="settings-selector"
      isSearchable={false}
      onChange={option => this.handleSelectChanged(option, 'company')}
    />;
  };

  private renderAccessLevelSelect = () => () => (
    <Select
      defaultValue={this.state.accessLevel}
      options={ACCESS_LEVEL_SELECTOR_OPTIONS}
      className="settings-selector"
      classNamePrefix="settings-selector"
      isSearchable={false}
      onChange={option => this.handleSelectChanged(option, 'accessLevel')}
    />
  );

  public render() {
    const { isPassShowing } = this.state;
    const { selectedUser } = this.props;

    return (
      <div className={styles.editor}>
        <p className={styles.title}>{selectedUser ? `Update User: ${selectedUser.email}` : 'Add User'}</p>
        <div className={styles.formWrapper}>
          <Formik
            initialValues={this.getInitialValues()}
            validationSchema={this.getSchemas()}
            onSubmit={this.onFormSubmit}
          >
            {({ isSubmitting }) => (
              <Form className={`${styles.form} ${styles.columnsForm}`}>
                <div className={styles.formColumn}>
                  <label className={styles.formField}>
                    <span className={styles.formFieldField}>Email</span>
                    <Field type="text" name="email" placeholder="Email" />
                    <ErrorMessage
                      name="email"
                      component="p"
                      className={styles.formFieldError}
                    />
                  </label>
                  <label className={styles.formField}>
                    <span className={styles.formFieldField}>First name</span>
                    <Field type="text" name="firstName" placeholder="First name" />
                    <ErrorMessage
                      name="firstName"
                      component="p"
                      className={styles.formFieldError}
                    />
                  </label>
                  <label className={styles.formField}>
                    <span className={styles.formFieldField}>Last name</span>
                    <Field type="text" name="lastName" placeholder="Last name" />
                    <ErrorMessage
                      name="lastName"
                      component="p"
                      className={styles.formFieldError}
                    />
                  </label>
                  <label className={styles.formField}>
                    <span className={styles.formFieldField}>Username</span>
                    <Field type="text" name="username" placeholder="Username" />
                    <ErrorMessage
                      name="username"
                      component="p"
                      className={styles.formFieldError}
                    />
                  </label>
                </div>
                <div className={styles.formColumn}>
                  <label className={styles.formField}>
                    <span className={styles.formFieldField}>Password</span>
                    <button
                      type="button"
                      className={`${styles.formFieldShowPass} ${isPassShowing ? styles.active : ''}`}
                      onClick={this.passToggle}
                    />
                    <Field type={isPassShowing ? 'text' : 'password'} name="password" placeholder="Password" />
                    <ErrorMessage
                      name="password"
                      component="p"
                      className={styles.formFieldError}
                    />
                  </label>
                  <label className={styles.formField}>
                    <span className={styles.formFieldField}>Phone number</span>
                    <Field type="text" name="phone" placeholder="Phone number" />
                    <ErrorMessage
                      name="phone"
                      component="p"
                      className={styles.formFieldError}
                    />
                  </label>
                  <label className={styles.formField}>
                    <span className={styles.formFieldField}>Company</span>
                    <Field
                      component={this.renderCompanySelect()}
                      type="text"
                      name="company"
                      placeholder="Company"
                    />
                    <ErrorMessage
                      name="company"
                      component="p"
                      className={styles.formFieldError}
                    />
                  </label>
                  <label className={styles.formField}>
                    <span className={styles.formFieldField}>Access level</span>
                    <Field component={this.renderAccessLevelSelect()} type="text" name="accessLevel" placeholder="Access level" />
                    <ErrorMessage
                      name="accessLevel"
                      component="p"
                      className={styles.formFieldError}
                    />
                  </label>
                </div>
                <div className={styles.bottom}>
                  <button type="submit" className={styles.formBtn} disabled={isSubmitting}>{selectedUser ? 'Update' : 'Save'}</button>
                  <button type="button" className={`${styles.formBtn} ${styles.cancel}`} onClick={this.onCancel}>Cancel</button>
                  {selectedUser && (
                    <button type="button" className={`${styles.formBtn} ${styles.delete}`} onClick={this.onDelete}>Delete</button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(UserEditor);
