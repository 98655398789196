import { IOption } from '../types/ISelect';
import { APP_CONSTANTS } from './global';

export const CHART_SELECTOR_OPTIONS: IOption[] = [
  {
    value: 'doorsKnocked',
    label: 'Doors Knocked',
  },
  {
    value: 'leadsActual',
    label: 'Leads Actual',
  },
  {
    value: 'hoursKnocking',
    label: 'Hours Knocking',
  },
  {
    value: 'evaluations',
    label: 'Evaluations',
  },
  {
    value: 'insuranceLooks',
    label: 'Insurance Looks',
  },
  {
    value: 'adjusterMeetings',
    label: 'Adjuster Meetings',
  },
  {
    value: 'approvals',
    label: 'Approvals',
  },
  {
    value: 'totalColorMeetings',
    label: 'Total Color Meetings',
  },
  {
    value: 'projectsTurnedIn',
    label: 'Projects Turned in',
  },
  {
    value: 'dollarsBooked',
    label: 'Dollars Booked',
  },
  {
    value: 'dollarsClosed',
    label: 'Dollars Closed',
  },
];

export const ACCESS_LEVEL_SELECTOR_OPTIONS: IOption[] = [
  {
    value: APP_CONSTANTS.ACCESS_LEVELS.EMPLOYEE,
    label: 'Salesperson',
  },
  {
    value: APP_CONSTANTS.ACCESS_LEVELS.ADMIN,
    label: 'Admin',
  },
];

export const CHART_DEFAULT_OPTION: IOption = CHART_SELECTOR_OPTIONS[0];
export const ACCESS_LEVEL_DEFAULT_OPTION: IOption = ACCESS_LEVEL_SELECTOR_OPTIONS[0];

export const REPORT_SCHEDULE_OPTIONS: IOption[] = [
  {
    value: 0,
    label: 'Daily',
  },
  {
    value: 1,
    label: 'Weekly',
  },
  {
    value: 2,
    label: 'Monthly',
  }
];
