import moment from './moment';

import { GROUP_ID_KEYS } from '../constants/report';

import { IFiltersQuickPeriods } from '../types/IFilters';

export const formatReportTime = (date: string | number, withWeekDay: boolean = false): string =>
  moment(date).format(withWeekDay ? 'dddd MM.DD.YYYY' : 'MM/DD/YYYY');

export const formatReportTimeShort = (date: string | number): string =>
  moment(date).format('MMM DD YYYY');

export const formatChartTime = (date: string | number, idKey: string): string => {

  if ( idKey === GROUP_ID_KEYS.DAY ) {
    return 'Day: ' + moment(date).format('MM.DD.YYYY');
  } else if ( idKey === GROUP_ID_KEYS.WEEK ) {
    return 'Week: ' + moment(date).format('Wo, YYYY');
  }

  return 'Month: ' + moment(date).format('MMMM, YYYY');
};

export const getQuickFilterDate = ({ amount, amountOf }: IFiltersQuickPeriods): string =>
  // @ts-ignore
  moment.utc().subtract(amount, amountOf).valueOf();
