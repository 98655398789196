import React from 'react';
import ReactTooltip from 'react-tooltip';

import { INotification } from '../../types/INotification';

import moment from '../../utils/moment';
import { isValueDiff, getRate } from '../../utils/report.util';

import styles from './Notifications.module.scss'
import UserIcon from '../../assets/imgs/user_icon.svg';

interface IProps {
  notification: INotification;
  onConfirmed: (_id: string) => void;
  onRejected: (_id: string) => void;
  onRemoved: (_id: string) => void;
}

export const Notification: React.FC<IProps> = ({ notification, onConfirmed, onRejected, onRemoved }) => {
  const { user, report } = notification;

  const onConfirm = () => onConfirmed(notification._id);

  const onReject = () => onRejected(notification._id);

  const onRemove = () => onRemoved(notification._id);

  return (
    <div className={styles.notification}>
      <div className={styles.notificationHeader}>
        <div className={styles.user}>
          <div className={styles.userImg}>
            <img
              src={user?.profileImg || UserIcon}
              alt={user?.username || user?.email}
            />
          </div>
          <div className={styles.info}>
            <p className={styles.userName}>
              {user?.firstName && user?.lastName
                ? user.firstName + '' + user.lastName
                : user?.username ? user.username : user?.email}
            </p>
            <p className={styles.created}>{moment(notification.createdAt).format('MMMM DD, YYYY, h:mm A')}</p>
          </div>
        </div>
        <div className={styles.actions}>
          <button className={styles.actionBtn} onClick={onConfirm}>Confirm</button>
          <button className={`${styles.actionBtn} ${styles.reject}`} onClick={onReject}>Reject</button>
          <button className={styles.actionDelete} onClick={onRemove} />
        </div>
      </div>
      <div className={styles.notifReport}>
        {notification.requestType === 'update' && (
          <p className={styles.created}>
            User <span className={styles.userNameInfo}>{user?.firstName && user?.lastName
            ? user.firstName + '' + user.lastName
            : user?.username ? user.username : user?.email}</span> <span className={styles.update}>added a new entry</span> for {moment(notification.createdAt).format('MMMM DD, YYYY')}.
          </p>
        )}
        {notification.requestType === 'delete' && (
          <p className={styles.created}>
            User <span className={styles.userNameInfo}>{user?.firstName && user?.lastName
            ? user.firstName + '' + user.lastName
            : user?.username ? user.username : user?.email}</span> wanted to <span className={styles.remove}>delete</span> this report.
          </p>
        )}
        <div className={styles.items}>
          <div className={styles.column}>
            <div className={styles.item}>
              <p className={styles.itemName}>Doors Knocked</p>
              <p
                className={`
                  ${styles.itemValue}
                  ${isValueDiff(notification.doorsKnocked as number, report?.doorsKnocked) ? styles.changed : ''}
                `}
                data-tip={
                  isValueDiff(notification.doorsKnocked as number, report?.doorsKnocked) ? 'Previous: ' + report?.doorsKnocked : ''
                }
              >
                {notification.doorsKnocked}
              </p>
            </div>
            <div className={styles.item}>
              <p className={styles.itemName}>Evaluations</p>
              <p
                className={`
                  ${styles.itemValue}
                  ${isValueDiff(notification.evaluations as number, report?.evaluations) ? styles.changed : ''}
                `}
                data-tip={
                  isValueDiff(notification.evaluations as number, report?.evaluations) ? 'Previous: ' + report?.evaluations : ''
                }
              >
                {notification.evaluations}
              </p>
            </div>
            <div className={styles.item}>
              <p className={styles.itemName}>Adjuster Meetings</p>
              <p
                className={`
                  ${styles.itemValue}
                  ${isValueDiff(notification.adjusterMeetings as number, report?.adjusterMeetings) ? styles.changed : ''}
                `}
                data-tip={
                  isValueDiff(notification.adjusterMeetings as number, report?.adjusterMeetings) ? 'Previous: ' + report?.adjusterMeetings : ''
                }
              >
                {notification.adjusterMeetings}
              </p>
            </div>
            <div className={styles.item}>
              <p className={styles.itemName}>Approvals</p>
              <p
                className={`
                  ${styles.itemValue}
                  ${isValueDiff(notification.approvals as number, report?.approvals) ? styles.changed : ''}
                `}
                data-tip={
                  isValueDiff(notification.approvals as number, report?.approvals) ? 'Previous: ' + report?.approvals : ''
                }
              >
                {notification.approvals}
              </p>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.item}>
              <p className={styles.itemName}>Total Color Meetings</p>
              <p
                className={`
                  ${styles.itemValue}
                  ${isValueDiff(notification.totalColorMeetings as number, report?.totalColorMeetings) ? styles.changed : ''}
                `}
                data-tip={
                  isValueDiff(notification.totalColorMeetings as number, report?.totalColorMeetings) ? 'Previous: ' + report?.totalColorMeetings : ''
                }
              >
                {notification.totalColorMeetings}
              </p>
            </div>
            <div className={styles.item}>
              <p className={styles.itemName}>Hours Knocking</p>
              <p
                className={`
                  ${styles.itemValue}
                  ${isValueDiff(notification.hoursKnocking as number, report?.hoursKnocking) ? styles.changed : ''}
                `}
                data-tip={
                  isValueDiff(notification.hoursKnocking as number, report?.hoursKnocking) ? 'Previous: ' + report?.hoursKnocking : ''
                }
              >
                {notification.hoursKnocking}
              </p>
            </div>
            <div className={styles.item}>
              <p className={styles.itemName}>Insurance Looks</p>
              <p
                className={`
                  ${styles.itemValue}
                  ${isValueDiff(notification.insuranceLooks as number, report?.insuranceLooks) ? styles.changed : ''}
                `}
                data-tip={
                  isValueDiff(notification.insuranceLooks as number, report?.insuranceLooks) ? 'Previous: ' + report?.insuranceLooks : ''
                }
              >
                {notification.insuranceLooks}
              </p>
            </div>
            <div className={styles.item}>
              <p className={styles.itemName}>Projects Turned in</p>
              <p
                className={`
                  ${styles.itemValue}
                  ${isValueDiff(notification.projectsTurnedIn as number, report?.projectsTurnedIn) ? styles.changed : ''}
                `}
                data-tip={
                  isValueDiff(notification.projectsTurnedIn as number, report?.projectsTurnedIn) ? 'Previous: ' + report?.projectsTurnedIn : ''
                }
              >
                {notification.projectsTurnedIn}
              </p>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.item}>
              <p className={styles.itemName}>Day rating</p>
              <p className={styles.itemValueRating}>
                {notification.amountTime} {getRate('amountTime', notification.amountTime as number)}
                {isValueDiff(notification.amountTime as number, report?.amountTime) && (
                  <span className={styles.rateChanged} data-tip={'Previous: ' + getRate('amountTime', report?.amountTime as number)} />
                )}
              </p>
            </div>
            <div className={styles.item}>
              <p className={styles.itemName}>Time spent on work</p>
              <p className={styles.itemValueRating}>
                {notification.dayRate} {getRate('dayRate', notification.dayRate as number)}
                {isValueDiff(notification.dayRate as number, report?.dayRate) && (
                  <span className={styles.rateChanged} data-tip={'Previous: ' + getRate('dayRate', report?.dayRate as number)} />
                )}
              </p>
            </div>
            <div className={styles.item}>
              <p className={styles.itemName}>Completed daily goals</p>
              <p className={styles.itemValueRating}>
                {getRate('metGoals', notification.metGoals as number)}
                {isValueDiff(notification.metGoals as number, report?.metGoals) && (
                  <span className={styles.rateChanged} data-tip={'Previous: ' + getRate('metGoals', report?.metGoals as number)} />
                )}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.textArea}>
          <p className={styles.textAreaTitle}>Explain what you are feeling today regarding that area or areas:</p>
          <p className={styles.textAreaText} />
        </div>
      </div>
      <ReactTooltip
        type="light"
        className={styles.tooltip}
        effect="solid"
      />
    </div>
  )
};
