import { createReducer } from 'redux-create-reducer';

import { IPopups } from '../types/IPopups';
import { successAction } from '../utils/reduxBoilerplate';

export const popupsTypes = {
  toggleNotifications: 'TOGGLE_NOTIFICATIONS',
};

export const popupsActions = {
  toggleNotifications: successAction<IPopups>(popupsTypes.toggleNotifications),
};

// @ts-ignore
export const popupsReducer = createReducer(null, {
  [popupsTypes.toggleNotifications]: (state) => ({
    ...state,
    notifications: !state.notifications,
  })
});
