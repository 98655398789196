import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';

import { COMPANY_EDITOR_VALUES } from '../../constants/formsInitialValues';
import { COMPANY_EDITOR_SCHEMA } from '../../constants/formsYupSchemas';

import { Dropzone } from '../Dropzone/Dropzone';
import { CompanyConfirmation } from './CompanyConfirmation/CompanyConfirmation';

import { companiesActions } from '../../sagas/companies.saga';
import { history } from '../../store/store';

import { ICompany, ICompanyCreate, ICompanyLogoUpdate, ICompanyUpdate, ICompanyDelete } from '../../types/ICompany';

import styles from './CompanyEditor.module.scss';
import {IStore} from '../../types/IStore';
import {selectedCompanyActions} from '../../sagas/selectedCompany.saga';

interface IDispatchProps {
  createCompany: (payload: ICompanyCreate) => void;
  updateCompany: (payload: ICompanyUpdate) => void;
  deleteCompany: (payload: ICompanyDelete) => void;
  updateCompanyLogo: (payload: ICompanyLogoUpdate) => void;
  deleteCompanyLogo: (payload: string) => void;
  clearSelectedCompany: () => void;
}

interface IPropsFromStore {
  companies: ICompany[];
  selectedCompany: ICompany | null;
}

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  createCompany: (payload: ICompanyCreate) => dispatch(companiesActions.create.update(payload)),
  updateCompany: (payload: ICompanyUpdate) => dispatch(companiesActions.update.update(payload)),
  deleteCompany: (payload: ICompanyDelete) => dispatch(companiesActions.delete.update(payload)),
  updateCompanyLogo: (payload: ICompanyLogoUpdate) => dispatch(companiesActions.logo.update(payload)),
  deleteCompanyLogo: (payload: string) => dispatch(companiesActions.deleteLogo.update(payload)),
  clearSelectedCompany: () => dispatch(selectedCompanyActions.clear.update()),
});

const mapStateToProps = ({ companies, selectedCompany }: IStore): IPropsFromStore => ({
  companies,
  selectedCompany,
});

type IProps = IPropsFromStore & IDispatchProps;

class CompanyEditor extends Component<IProps> {
  private imgFile: File | null | undefined;

  public componentWillUnmount(): void {
    this.props.clearSelectedCompany();
  }

  private imgDropped = (imgFile: File | null) => (this.imgFile = imgFile);

  private onFormSubmit = ({companyName}, { setSubmitting }: FormikHelpers<any>) => {
    const { selectedCompany } = this.props;

    if ( !selectedCompany ) {
      this.props.createCompany({
        name: companyName,
        file: this.imgFile instanceof File ? this.imgFile : null,
      });
    } else {
      this.props.updateCompany({
        name: companyName,
        _id: selectedCompany._id,
      });
      if (this.imgFile instanceof File) {
        this.props.updateCompanyLogo({
          file: this.imgFile,
          _id: selectedCompany._id,
        });
      } else if (this.imgFile === null) {
        this.props.deleteCompanyLogo(selectedCompany._id);
      }
    }

    setSubmitting(false);
  };

  private onCancel = () => history.goBack();

  private deleteCompany = () => {
    this.props.deleteCompany({
      _id: this.props.selectedCompany?._id as string,
    });
    toast.dismiss();
  };

  private onDelete = () => toast(() => <CompanyConfirmation title={'Delete this company?'} deleteApproved={this.deleteCompany} />);

  private getInitialValues = () => {
    if ( this.props.selectedCompany ) {
      return {
        companyName: this.props.selectedCompany.name,
      }
    }
    return COMPANY_EDITOR_VALUES;
  };

  public render() {
    const { selectedCompany } = this.props;
    return (
      <div className={styles.editor}>
        <p className={styles.title}>{selectedCompany ? `Edit Company "${selectedCompany.name}"` : 'Add Company'}</p>
        <div className={styles.formWrapper}>
          <Formik
            initialValues={this.getInitialValues()}
            validationSchema={COMPANY_EDITOR_SCHEMA}
            onSubmit={this.onFormSubmit}
          >
            {({ isSubmitting }) => (
              <Form className={styles.form}>
                <label className={styles.formField}>
                  <span className={styles.formFieldField}>Company name</span>
                  <Field type="text" name="companyName" placeholder="Company name" />
                  <ErrorMessage
                    name="companyName"
                    component="p"
                    className={styles.formFieldError}
                  />
                </label>
                <div className={styles.img}>
                  <Dropzone
                    onImgDropped={this.imgDropped}
                    isImage={true}
                    profileImg={selectedCompany?.logo}
                    title={'Company logo'}
                    alignLeft={true}
                  />
                </div>
                <div className={styles.bottom}>
                  <button type="submit" className={styles.formBtn} disabled={isSubmitting}>{selectedCompany ? 'Update' : 'Save'}</button>
                  <button type="button" className={`${styles.formBtn} ${styles.cancel}`} onClick={this.onCancel}>Cancel</button>
                  {selectedCompany && (
                    <button type="button" className={`${styles.formBtn} ${styles.delete}`} onClick={this.onDelete}>Delete</button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(CompanyEditor);
