import React from 'react';

import styles from './CompanyConfirmation.module.scss'

interface IProps {
  deleteApproved: () => void;
  title: string;
}

export const CompanyConfirmation: React.FC<IProps> = ({ deleteApproved, title }) => (
  <div className={styles.confirmation}>
    <p>{title}</p>
    <div className={styles.confirmationBtns}>
      <button className={styles.btn} onClick={deleteApproved}>Approve</button>
    </div>
  </div>
);
