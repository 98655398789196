import { takeEvery, call, put } from 'redux-saga/effects';

import { IRating } from '../types/IRating';

import { http } from '../utils/requests';
import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';

import { API_ENDPOINTS } from '../constants/APi';

const resource = 'RATINGS';
const actions = ['get'];
type reducerType = IRating;

export const ratingsTypes = createActionTypes(resource, actions);
export const ratingsActions = createActions<reducerType>(resource, actions);
export const ratingsReducer = createReducers<reducerType>(resource, actions);

function* getRatings() {
  try {
    const response = yield call(http.get, API_ENDPOINTS.RATINGS);

    yield put(ratingsActions.get.success(response));
  } catch (err) {
    ratingsActions.get.fail(err);
  }
}

export function* ratingsSaga() {
  yield takeEvery(ratingsTypes.get.load, getRatings);
}
