import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';
import { APP_CONSTANTS } from '../constants/global';
import { ILoginPayload } from '../types/IPayloads';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { deleteFirebaseToken } from '../utils/push-notifications';

const resource = 'AUTH';
const actions = ['login', 'logOut', 'firebaseToken'];
type reducerType = IAuth;

export const authTypes = createActionTypes(resource, actions);
export const authActions = createActions<reducerType>(resource, actions);
export const authReducer = createReducers<reducerType>(resource, actions);

export interface IAuth {
  token: string | null;
  email?: string;
}

interface ILoginAction {
  payload: ILoginPayload;
}

function* login({ payload }: ILoginAction) {
  try {
    const { token } = yield call(http.post, API_ENDPOINTS.LOGIN, payload, {isAuth: false});
    localStorage.setItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_NAME, token);
    yield put(authActions.login.success(token));
  } catch (err) {
    yield put(authActions.login.fail(err));
  }
}

function* logOut({payload}: {payload: string}) {
  if ( localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_NAME) ) {
    try {
      yield call(http.delete, API_ENDPOINTS.LOGOUT, {
        _id: payload,
        token: localStorage.getItem(APP_CONSTANTS.FIREBASE_STORAGE_TOKEN_NAME),
      });
    } catch (err) {
      yield put(authActions.logOut.fail(err));
    } finally {
      localStorage.removeItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_NAME);
      deleteFirebaseToken();
    }
  }
  yield put(authActions.logOut.success(null));
}

function* saveFirebaseToken({payload}: {payload: string}) {
  try {
    yield call(http.post, API_ENDPOINTS.PROFILE_FIREBASE, {token: payload});
  } catch (err) {
    yield put(authActions.firebaseToken.fail(err));
  }
}

export function* authSaga() {
  yield takeEvery(authTypes.login.load, login);
  yield takeEvery(authTypes.logOut.update, logOut);
  yield takeEvery(authTypes.firebaseToken.update, saveFirebaseToken);
}
