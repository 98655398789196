import React from 'react';
import DatePicker from 'react-datepicker';
import { ErrorMessage, Field, Form, FormikProps } from 'formik';
import Select from 'react-select';

import { PICKER } from '../../../constants/datepicker.config';
import { REPORT_SCHEDULE_OPTIONS } from '../../../constants/select';

import { Dropzone } from '../../Dropzone/Dropzone';

import { IOption } from '../../../types/ISelect';

import styles from '../Settings.module.scss';

interface IOuterProps {
  imgDropped: (file: File | null) => void;
  profileImg: string | undefined;
  reportSchedule: IOption;
  reportScheduleChanged: (option: IOption) => void;
}

type IProps = FormikProps<any> & IOuterProps;

export const GeneralForm: React.FC<IProps> = props => {
  const setDateOfBirth = (val: string) =>
    props.setFieldValue('dateOfBirth', val);

  const getSelectedDateOfBirth = (): Date | string => {
    const { dateOfBirth } = props.values;
    return dateOfBirth ? new Date(dateOfBirth) : '';
  };

  const handleSelectChanged = option => props.reportScheduleChanged(option);

  const renderReportScheduleSelect = () => () => {
    return <Select
      defaultValue={props.reportSchedule}
      options={REPORT_SCHEDULE_OPTIONS}
      className="settings-selector"
      classNamePrefix="settings-selector"
      isSearchable={false}
      onChange={handleSelectChanged}
    />
  };

  return (
    <Form className={styles.form}>
      <div className={styles.columns}>
        <div className={styles.column}>
          <label className={styles.field}>
            <p className={styles.fieldTitle}>First name</p>
            <Field type="text" name="firstName" placeholder="First name" />
            <ErrorMessage
              className={styles.fieldErrorMessage}
              name="firstName"
              component="p"
            />
          </label>
          <Field name="email">
            {({ field, meta }) => (
              <label
                className={`${styles.field} ${
                  meta.touched && meta.error ? styles.fieldError : ''
                }`}
              >
                <p className={styles.fieldTitle}>Email</p>
                <input
                  placeholder="Email"
                  type="text"
                  {...field}
                  disabled={true}
                />
                <ErrorMessage
                  className={styles.fieldErrorMessage}
                  name="email"
                  component="p"
                />
              </label>
            )}
          </Field>
          <label className={styles.field}>
            <p className={styles.fieldTitle}>Phone number</p>
            <Field type="text" name="phone" placeholder="Phone number" />
            <ErrorMessage
              className={styles.fieldErrorMessage}
              name="phone"
              component="p"
            />
          </label>
        </div>
        <div className={styles.column}>
          <label className={styles.field}>
            <p className={styles.fieldTitle}>Last name</p>
            <Field type="text" name="lastName" placeholder="Last name" />
            <ErrorMessage
              className={styles.fieldErrorMessage}
              name="lastName"
              component="p"
            />
          </label>
          <label className={styles.field}>
            <p className={styles.fieldTitle}>Date of birth</p>
            <div className={styles.datepicker}>
              <DatePicker
                {...PICKER}
                placeholderText="Date of birth"
                selected={getSelectedDateOfBirth()}
                onChange={setDateOfBirth}
                showYearDropdown={true}
                dropdownMode="select"
              />
            </div>
          </label>
          <label className={styles.field}>
            <p className={styles.fieldTitle}>address</p>
            <Field type="text" name="address" placeholder="address" />
            <ErrorMessage
              className={styles.fieldErrorMessage}
              name="address"
              component="p"
            />
          </label>
        </div>
        <div className={styles.column}>
          <label className={styles.field}>
            <p className={styles.fieldTitle}>Username</p>
            <Field type="text" name="username" placeholder="Username" />
            <ErrorMessage
              className={styles.fieldErrorMessage}
              name="username"
              component="p"
            />
          </label>
          <label className={styles.field}>
            <p className={styles.fieldTitle}>Company</p>
            <Field
              type="text"
              name="company"
              placeholder="Company"
              disabled={true}
            />
            <ErrorMessage
              className={styles.fieldErrorMessage}
              name="company"
              component="p"
            />
          </label>
          <label className={styles.field}>
            <p className={styles.fieldTitle}>Receive the work report</p>
            <Field
              component={renderReportScheduleSelect()}
              type="text"
              name="receiveReportEvery"
              placeholder="Receive the work report"
            />
            <ErrorMessage
              className={styles.fieldErrorMessage}
              name="receiveReportEvery"
              component="p"
            />
          </label>
        </div>
        <div className={styles.img}>
          <Dropzone
            onImgDropped={props.imgDropped}
            isImage={true}
            profileImg={props.profileImg}
          />
        </div>
      </div>
      <div className={styles.checkers}>
        <label className={styles.fieldCheckbox}>
          <Field type="checkbox" name="isEmailNotif" />
          <span className={styles.checkerCheckbox} />
          <p className={styles.fieldTitle}>Receive an email notifications</p>
        </label>
        <label className={styles.fieldCheckbox}>
          <Field type="checkbox" name="isAppNotif" />
          <span className={styles.checkerCheckbox} />
          <p className={styles.fieldTitle}>Receive an app notifications</p>
        </label>
      </div>
      <button type="submit">Save</button>
    </Form>
  );
};
