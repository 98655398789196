import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ROUTES } from '../../constants/router';

import { IReport } from '../../types/IReport';

import styles from './Sidebar.module.scss';

interface IOuterProps {
  blurred: boolean | IReport;
  onClickReport: () => void;
  admin: boolean;
}

export const Sidebar: React.FC<IOuterProps> = ({
  blurred,
  onClickReport,
  admin,
}) => {
  const [toggle, setToggle] = useState(true);

  const toggleSidebar = () => setToggle(!toggle);

  return (
    <div
      className={`${styles.sidebar} ${toggle ? styles.open : ''} ${
        blurred ? styles.blurred : ''
      }`}
    >
      <button className={styles.sidebarToggle} onClick={toggleSidebar}>
        <span className={styles.iconSvgArrowLeft} />
      </button>
      <nav className={styles.sidebarMenu}>
        <NavLink
          activeClassName={styles.sidebarMenuItemActive}
          to={ROUTES.Root.path}
          className={styles.sidebarMenuItem}
          exact={true}
        >
          <div className={styles.sidebarMenuItemContainer}>
            <div
              className={`${styles.sidebarMenuItemIcon} ${styles.iconSvgDashboard}`}
            />
            <p className={styles.sidebarMenuItemName}>Dashboard</p>
          </div>
        </NavLink>
        <NavLink
          activeClassName={styles.sidebarMenuItemActive}
          to={ROUTES.Analytics.path}
          className={styles.sidebarMenuItem}
        >
          <div className={styles.sidebarMenuItemContainer}>
            <div
              className={`${styles.sidebarMenuItemIcon} ${styles.iconSvgAnalytics}`}
            />
            <p className={styles.sidebarMenuItemName}>Analytics</p>
          </div>
        </NavLink>
        <NavLink
          activeClassName={styles.sidebarMenuItemActive}
          to={ROUTES.Rating.path}
          className={styles.sidebarMenuItem}
        >
          <div className={styles.sidebarMenuItemContainer}>
            <div
              className={`${styles.sidebarMenuItemIcon} ${styles.iconSvgRating}`}
            />
            <p className={styles.sidebarMenuItemName}>Rating</p>
          </div>
        </NavLink>
        {admin && (
          <NavLink
            activeClassName={styles.sidebarMenuItemActive}
            to={ROUTES.Admin.path}
            className={styles.sidebarMenuItem}
          >
            <div className={styles.sidebarMenuItemContainer}>
              <div
                className={`${styles.sidebarMenuItemIcon} ${styles.iconSvgAdmin}`}
              />
              <p className={styles.sidebarMenuItemName}>Admin panel</p>
            </div>
          </NavLink>
        )}
        <NavLink
          activeClassName={styles.sidebarMenuItemActive}
          to={ROUTES.Settings.path}
          className={styles.sidebarMenuItem}
        >
          <div className={styles.sidebarMenuItemContainer}>
            <div
              className={`${styles.sidebarMenuItemIcon} ${styles.iconSvgSettings}`}
            />
            <p className={styles.sidebarMenuItemName}>Settings</p>
          </div>
        </NavLink>
      </nav>
      <button className={styles.sidebarReportBtn} onClick={onClickReport} />
    </div>
  );
};
