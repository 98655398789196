export enum ValueState {
  neverLoaded,
  loading,
}

// todo make props not enumerable
export interface IValueState {
  _status: ValueState;
  _error?: any;
}

export const createNotLoadedState = () => ({
  _status: ValueState.neverLoaded,
});

export const passState = state => state;

export const createLoadingState = state => {
  if (state instanceof Array) {
    state = [...state];
    state._status = ValueState.loading;
    state._error = undefined;
    return state;
  } else {
    return {
      ...state,
      _status: ValueState.loading,
      _error: undefined,
    };
  }
};

export const createSuccessState = (state, data) => {
  if (data instanceof Array) {
    return [...data];
  } else if (data instanceof Object) {
    return {
      ...state,
      ...data,
      _status: undefined,
      _error: undefined,
    };
  } else {
    return data;
  }
};

export const createErrorState = (state, error) => {
  if (state instanceof Array) {
    const newState = [...state];
    // @ts-ignore
    newState._error = error;
    return state;
  } else {
    return {
      ...state,
      _error: error,
    };
  }
};

export const didNotStartLoading = value => value && value._status === ValueState.neverLoaded;

export const isReady = value =>
  value && value._status !== ValueState.neverLoaded && value._status !== ValueState.loading;

export const isLoading = value => value && value._status === ValueState.loading;

export const isError = value => value?._error;

export const getError = value => ({
  status: value._error?.status,
  message: value._error?.data,
});
