import {IFilters} from '../types/IFilters';

export const assignFiltersParams = (filters: IFilters, innerParams: string = '?', withoutUsers: boolean = false): string => {
  const { from, to, users } = filters;

  if ( from ) {
    innerParams += `&from=${from}`;
  }
  if ( to ) {
    innerParams += `&to=${to}`;
  }
  if ( users?.length && !withoutUsers ) {
    innerParams += `&users=${users.join(';')}`;
  }

  return innerParams;
};
