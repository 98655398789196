import { createReducer } from 'redux-create-reducer';

import { IChartTooltipParams } from '../types/IChartTooltip';
import { successAction } from '../utils/reduxBoilerplate';

export const tooltipTypes = {
  set: 'TOOLTIP_PARAMS_SET',
};

export const tooltipActions = {
  set: successAction<IChartTooltipParams>(tooltipTypes.set),
};

// @ts-ignore
export const tooltipReducer = createReducer(null, {
  [tooltipTypes.set]: (state, { data }) => data,
});
