import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { TABS } from '../../constants/admin';
import { ROUTES } from '../../constants/router';

import { isReady } from '../../utils/valueState';

import { history } from '../../store/store';

import { usersActions } from '../../sagas/users.saga';
import { companiesActions } from '../../sagas/companies.saga';

import { IStore } from '../../types/IStore';
import { ICompany } from '../../types/ICompany';
import { IUser } from '../../types/IUser';

import CompaniesTab from './Tabs/CompaniesTab/CompaniesTab';
import UsersTab from './Tabs/UsersTab/UsersTab';

import styles from './AdminPanel.module.scss';

interface IPropsFromStore {
  companies: ICompany[];
  users: IUser[];
}

interface IDispatchProps {
  getCompanies: () => void;
  getUsers: () => void;
}

const mapStateToProps = ({ companies, users }: IStore): IPropsFromStore => ({
  companies,
  users,
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  getCompanies: () => dispatch(companiesActions.get.load()),
  getUsers: () => dispatch(usersActions.get.load()),
});

interface IState {
  activeTab: number;
}

type IProps = IPropsFromStore & IDispatchProps;

class AdminPanel extends Component<IProps, IState> {
  public state: Readonly<IState> = {
    activeTab: TABS.USERS.number,
  };

  public componentDidMount(): void {
    if ( !isReady(this.props.companies) ) {
      this.props.getCompanies();
    }
    if ( !isReady(this.props.users) ) {
      this.props.getUsers();
    }
  }

  private onActiveTabSelect = (activeTab: number) => () =>
    this.setState({
      activeTab
    });

  private goToCompanyEditor = () => history.push(ROUTES.CompanyEditor.path);

  private goToUserEditor = () => history.push(ROUTES.UserEditor.path);

  private renderTabs = () => Object.values(TABS).map( ({name, number}) => (
    <li key={name + number} className={`${styles.tab} ${this.state.activeTab === number ? styles.active : ''}`} onClick={this.onActiveTabSelect(number)}>
      {name}
    </li>
  ));

  public render() {
    const { activeTab } = this.state;
    const { companies, users } = this.props;

    return (
      <div className={styles.adminPanel}>
        {isReady(companies) && isReady(users) && (
          <>
            <div className={styles.header}>
              <ul className={styles.tabs}>
                {this.renderTabs()}
              </ul>
              {activeTab === TABS.COMPANIES.number && <button onClick={this.goToCompanyEditor} className={styles.headerBtn}>Add Company</button>}
              {activeTab === TABS.USERS.number && <button onClick={this.goToUserEditor} className={styles.headerBtn}>Add User</button>}
            </div>
            {activeTab === TABS.USERS.number &&  <UsersTab users={users} />}
            {activeTab === TABS.COMPANIES.number && <CompaniesTab />}
          </>
        )}
      </div>
    )
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps,
)(AdminPanel);
