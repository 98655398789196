import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { REPORT_FORM_VALUES } from '../../constants/formsInitialValues';
import { REPORT_FORM_SCHEMA } from '../../constants/formsYupSchemas';
import { PICKER } from '../../constants/datepicker.config';
import {
  DAY_RATE,
  DAY_RATE_NAME,
  AMOUNT_TIME,
  AMOUNT_TIME_NAME,
  MET_GOALS,
  MET_GOALS_NAME,
  FEELING_EXPLAIN
} from '../../constants/rating';

import { activeReportActions } from '../../sagas/activeReport.saga';

import { checkIsReportInActiveReport } from '../../utils/report.util';
import moment from '../../utils/moment';

import { IReport } from '../../types/IReport';
import { IStore } from '../../types/IStore';

import { CompanyConfirmation } from '../CompanyEditor/CompanyConfirmation/CompanyConfirmation';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './Report.module.scss';

interface IPropsFromStore {
  activeReport: IReport | boolean;
}

interface IDispatchProps {
  closeReportForm: (payload: boolean) => void;
  sendNewReport: (payload: IReport) => void;
  updateReport: (payload: IReport) => void;
  deleteReport: (payload: string) => void;
  getReport: (payload: number) => void;
}

interface IState {
  reportDate: number;
}

type IProps = IDispatchProps & IPropsFromStore;

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  closeReportForm: (payload: boolean) =>
    dispatch(activeReportActions.openForm.update(payload)),
  sendNewReport: (payload: IReport) =>
    dispatch(activeReportActions.add.load(payload)),
  updateReport: (payload: IReport) =>
    dispatch(activeReportActions.update.update(payload)),
  deleteReport: (payload: string) =>
    dispatch(activeReportActions.delete.update(payload)),
  getReport: (payload: number) => dispatch(activeReportActions.get.load(payload)),
});

const mapStateToProps = ({ activeReport }: IStore): IPropsFromStore => ({
  activeReport,
});

class Report extends Component<IProps, IState> {
  public state: Readonly<IState> = {
    reportDate: moment().set({hours: 12, minutes: 0}).valueOf(),
  };

  public componentDidMount(): void {
    if ( checkIsReportInActiveReport(this.props.activeReport) ) {
      this.setState({
        reportDate: moment((this.props.activeReport as IReport).createdAt).valueOf(),
      });
    } else {
      this.props.getReport(this.state.reportDate);
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if ( checkIsReportInActiveReport(this.props.activeReport) && (prevProps.activeReport as IReport)._id !== (this.props.activeReport as IReport)._id ) {
      this.setState({
        reportDate: moment((this.props.activeReport as IReport).createdAt).valueOf(),
      });
    }
  }

  private closeReport = () => this.props.closeReportForm(false);

  private deleteReport = () => {
    toast.dismiss();
    this.props.deleteReport((this.props.activeReport as IReport)?._id || '');
  };

  private onDelete = () => toast(() => <CompanyConfirmation title={'Delete this report?'} deleteApproved={this.deleteReport} />);

  private onSubmit = (values, { setSubmitting }: FormikHelpers<any>) => {
    if ( checkIsReportInActiveReport(this.props.activeReport) ) {
      this.props.updateReport({
        ...values,
        createdAt: this.state.reportDate,
        reportID: (this.props.activeReport as IReport)._id,
      })
    } else {
      this.props.sendNewReport({
        ...values,
        createdAt: this.state.reportDate
      });
    }

    setSubmitting(false);
  };

  private renderDayRateSelect = () => DAY_RATE.map( rate => (
    <li key={rate.value} className={styles.radioSelectListItem}>
      <label className={styles.radioSelectListLabel} data-tip={rate.name}>
        <input
          type="radio"
          name={DAY_RATE_NAME}
          value={rate.value}
          defaultChecked={(this.props.activeReport as IReport).dayRate === +rate.value}
        />
        <span
          className={styles.radioSelectListDot}
          style={{
            backgroundColor: rate.color,
          }}
        />
      </label>
    </li>
  ));

  private renderAmountTimeSelect = () => AMOUNT_TIME.map( rate => (
    <li key={rate.value} className={styles.radioSelectListItem}>
      <label className={styles.radioSelectListLabel} data-tip={rate.name}>
        <input
          value={rate.value}
          type="radio"
          name={AMOUNT_TIME_NAME}
          defaultChecked={(this.props.activeReport as IReport).amountTime === +rate.value}
        />
        <span
          className={styles.radioSelectListDot}
          style={{
            backgroundColor: rate.color,
          }}
        />
      </label>
    </li>
  ));

  private setReportDate = date =>
    this.setState({
      reportDate: moment(new Date(date)).set({hours: 12, minutes: 0}).valueOf(),
    }, () => this.props.getReport(this.state.reportDate));

  private getInitialValues = () => {
    const { activeReport } = this.props;

    if ( checkIsReportInActiveReport(activeReport) ) {
      return Object.keys(REPORT_FORM_VALUES).reduce((values, key) => ({
        ...values,
        [key]: activeReport[key],
      }), {});
    } else {
      return REPORT_FORM_VALUES;
    }
  };

  private getMetGoalsDefaultValue(field: number): boolean {
    const { activeReport } = this.props;

    return checkIsReportInActiveReport(activeReport)
      ? (activeReport as IReport).metGoals === field : field === 1;
  }

  public render() {
    const { activeReport } = this.props;

    return (
      <div className={styles.report}>
        <div className={styles.reportHeader}>
          <p className={styles.reportHeaderTitle}>Date of reporting</p>
          <DatePicker
            {...PICKER}
            selected={this.state.reportDate}
            className={styles.reportHeaderDate}
            onChange={this.setReportDate}
          />
        </div>
        <Formik
          initialValues={this.getInitialValues()}
          validationSchema={REPORT_FORM_SCHEMA}
          onSubmit={this.onSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, values }) => {
            const dayRate = DAY_RATE.find( rate => rate.value === values[DAY_RATE_NAME]);
            const amountTime = AMOUNT_TIME.find( time => time.value === values[AMOUNT_TIME_NAME]);

            return (
              <Form>
                <div className={styles.reportContent}>
                  <div className={styles.reportContentColumn}>
                    <Field type="number" name="doorsKnocked">
                      {({ field, meta }) => (
                        <label
                          className={`${styles.reportField} ${
                            meta.touched && meta.error
                              ? styles.reportFieldErr
                              : ''
                          }`}
                        >
                          <div className={styles.reportFieldFlex}>
                            <span>Doors knocked</span>
                            <input placeholder="-" type="number" {...field} />
                          </div>
                          <ErrorMessage
                            className={styles.reportFieldErrMsg}
                            name="doorsKnocked"
                            component="div"
                          />
                        </label>
                      )}
                    </Field>
                    <Field type="number" name="hoursKnocking">
                      {({ field, meta }) => (
                        <label
                          className={`${styles.reportField} ${
                            meta.touched && meta.error
                              ? styles.reportFieldErr
                              : ''
                          }`}
                        >
                          <div className={styles.reportFieldFlex}>
                            <span>Hours knocking</span>
                            <input placeholder="-" type="number" {...field} />
                          </div>
                          <ErrorMessage
                            className={styles.reportFieldErrMsg}
                            name="hoursKnocking"
                            component="div"
                          />
                        </label>
                      )}
                    </Field>
                    <Field type="number" name="leadsActual">
                      {({ field, meta }) => (
                        <label
                          className={`${styles.reportField} ${
                            meta.touched && meta.error
                              ? styles.reportFieldErr
                              : ''
                          }`}
                        >
                          <div className={styles.reportFieldFlex}>
                            <span>Leads Actual</span>
                            <input placeholder="-" type="number" {...field} />
                          </div>
                          <ErrorMessage
                            className={styles.reportFieldErrMsg}
                            name="leadsActual"
                            component="div"
                          />
                        </label>
                      )}
                    </Field>
                    <Field type="number" name="evaluations">
                      {({ field, meta }) => (
                        <label
                          className={`${styles.reportField} ${
                            meta.touched && meta.error
                              ? styles.reportFieldErr
                              : ''
                          }`}
                        >
                          <div className={styles.reportFieldFlex}>
                            <span>Evaluations</span>
                            <input placeholder="-" type="number" {...field} />
                          </div>
                          <ErrorMessage
                            className={styles.reportFieldErrMsg}
                            name="evaluations"
                            component="div"
                          />
                        </label>
                      )}
                    </Field>
                    <Field type="number" name="insuranceLooks">
                      {({ field, meta }) => (
                        <label
                          className={`${styles.reportField} ${
                            meta.touched && meta.error
                              ? styles.reportFieldErr
                              : ''
                          }`}
                        >
                          <div className={styles.reportFieldFlex}>
                            <span>Insurance looks</span>
                            <input placeholder="-" type="number" {...field} />
                          </div>
                          <ErrorMessage
                            className={styles.reportFieldErrMsg}
                            name="insuranceLooks"
                            component="div"
                          />
                        </label>
                      )}
                    </Field>
                    <div className={styles.radioSelect} style={{ marginTop: '66px' }}>
                      <p className={styles.fieldTitle}>How do you rate your day today?</p>
                      <div className={styles.radioSelectColumn}>
                        <Field component="ul" name={DAY_RATE_NAME} className={styles.radioSelectList}>
                          {this.renderDayRateSelect()}
                        </Field>
                        {dayRate && (
                          <p
                            className={styles.radioSelectValue}
                            style={{color: dayRate.color}}
                          >{dayRate.name}</p>
                        )}
                      </div>
                      <ErrorMessage
                        className={styles.reportFieldErrMsg}
                        name={DAY_RATE_NAME}
                        component="div"
                      />
                    </div>
                    <div className={styles.singleLabel}>
                      <label htmlFor="feelingExplain">
                        Explain what you are feeling today
                        regarding that area or areas
                      </label>
                    </div>
                  </div>
                  <div className={styles.reportContentColumn}>
                    <Field type="number" name="adjusterMeetings">
                      {({ field, meta }) => (
                        <label
                          className={`${styles.reportField} ${
                            meta.touched && meta.error
                              ? styles.reportFieldErr
                              : ''
                          }`}
                        >
                          <div className={styles.reportFieldFlex}>
                            <span>Adjuster meetings</span>
                            <input placeholder="-" type="number" {...field} />
                          </div>
                          <ErrorMessage
                            className={styles.reportFieldErrMsg}
                            name="adjusterMeetings"
                            component="div"
                          />
                        </label>
                      )}
                    </Field>
                    <Field type="number" name="approvals">
                      {({ field, meta }) => (
                        <label
                          className={`${styles.reportField} ${
                            meta.touched && meta.error
                              ? styles.reportFieldErr
                              : ''
                          }`}
                        >
                          <div className={styles.reportFieldFlex}>
                            <span>Approvals</span>
                            <input placeholder="-" type="number" {...field} />
                          </div>
                          <ErrorMessage
                            className={styles.reportFieldErrMsg}
                            name="approvals"
                            component="div"
                          />
                        </label>
                      )}
                    </Field>
                    <Field type="number" name="totalColorMeetings">
                      {({ field, meta }) => (
                        <label
                          className={`${styles.reportField} ${
                            meta.touched && meta.error
                              ? styles.reportFieldErr
                              : ''
                          }`}
                        >
                          <div className={styles.reportFieldFlex}>
                            <span>Total color meetings</span>
                            <input placeholder="-" type="number" {...field} />
                          </div>
                          <ErrorMessage
                            className={styles.reportFieldErrMsg}
                            name="totalColorMeetings"
                            component="div"
                          />
                        </label>
                      )}
                    </Field>
                    <Field type="number" name="projectsTurnedIn">
                      {({ field, meta }) => (
                        <label
                          className={`${styles.reportField} ${
                            meta.touched && meta.error
                              ? styles.reportFieldErr
                              : ''
                          }`}
                        >
                          <div className={styles.reportFieldFlex}>
                            <span>Projects turned in</span>
                            <input placeholder="-" type="number" {...field} />
                          </div>
                          <ErrorMessage
                            className={styles.reportFieldErrMsg}
                            name="projectsTurnedIn"
                            component="div"
                          />
                        </label>
                      )}
                    </Field>
                    <Field type="number" name="dollarsBooked">
                      {({ field, meta }) => (
                        <label
                          className={`${styles.reportField} ${
                            meta.touched && meta.error
                              ? styles.reportFieldErr
                              : ''
                          }`}
                        >
                          <div className={styles.reportFieldFlex}>
                            <span>Dollars Booked</span>
                            <input placeholder="-" type="number" {...field} />
                          </div>
                          <ErrorMessage
                            className={styles.reportFieldErrMsg}
                            name="dollarsBooked"
                            component="div"
                          />
                        </label>
                      )}
                    </Field>
                    <Field type="number" name="dollarsClosed">
                      {({ field, meta }) => (
                        <label
                          className={`${styles.reportField} ${
                            meta.touched && meta.error
                              ? styles.reportFieldErr
                              : ''
                          }`}
                        >
                          <div className={styles.reportFieldFlex}>
                            <span>Dollars Closed</span>
                            <input placeholder="-" type="number" {...field} />
                          </div>
                          <ErrorMessage
                            className={styles.reportFieldErrMsg}
                            name="dollarsClosed"
                            component="div"
                          />
                        </label>
                      )}
                    </Field>
                    <div className={styles.radioSelect}>
                      <p className={styles.fieldTitle}>What's your amount time today?</p>
                      <div className={styles.radioSelectColumn}>
                        <Field component="ul" name={AMOUNT_TIME_NAME} className={styles.radioSelectList}>
                          {this.renderAmountTimeSelect()}
                        </Field>
                        {amountTime && (
                          <p
                            className={styles.radioSelectValue}
                            style={{color: amountTime.color}}
                          >{amountTime.name}</p>
                        )}
                      </div>
                      <ErrorMessage
                        className={styles.reportFieldErrMsg}
                        name={AMOUNT_TIME_NAME}
                        component="div"
                      />
                    </div>
                    <div className={styles.metGoals}>
                      <p className={styles.fieldTitle}>Today met your goals?</p>
                      <Field component="ul" name={MET_GOALS_NAME} className={styles.radioSelectList}>
                        <li className={styles.radioSelectListItem}>
                          <label className={styles.radioSelectListLabel}>
                            <input
                              value={MET_GOALS.YES.value}
                              defaultChecked={this.getMetGoalsDefaultValue(1)}
                              type="radio"
                              name={MET_GOALS_NAME}
                            />
                            <span
                              className={styles.radioSelectListDot}
                              style={{
                                backgroundColor: MET_GOALS.YES.color,
                              }}
                            />
                            <span className={styles.radioSelectName}>Yes</span>
                          </label>
                        </li>
                        <li className={styles.radioSelectListItem}>
                          <label className={styles.radioSelectListLabel}>
                            <input
                              value={MET_GOALS.NO.value}
                              type="radio"
                              name={MET_GOALS_NAME}
                              defaultChecked={this.getMetGoalsDefaultValue(0)}
                            />
                            <span
                              className={styles.radioSelectListDot}
                              style={{
                                backgroundColor: MET_GOALS.NO.color,
                              }}
                            />
                            <span className={styles.radioSelectName}>No</span>
                          </label>
                        </li>
                      </Field>
                    </div>
                  </div>
                  <div className={styles.fullField}>
                    <Field component="textarea" id="feelingExplain" name={FEELING_EXPLAIN} className={styles.fullFieldArea} />
                  </div>
                </div>
                <div className={styles.reportBottom}>
                  <button
                    className={styles.reportBottomBtn}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {checkIsReportInActiveReport(activeReport) ? 'Update' : 'Save'}
                  </button>
                  <button
                    type="button"
                    className={`${styles.reportBottomBtn} ${styles.cancel}`}
                    onClick={this.closeReport}
                  >
                    Cancel
                  </button>
                  {checkIsReportInActiveReport(activeReport) && (
                    <button
                      type="button"
                      className={`${styles.reportBottomBtn} ${styles.delete}`}
                      onClick={this.onDelete}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </Form>
            )
          }}
        </Formik>
        <ReactTooltip
          type="light"
          className={styles.tooltip}
          effect="solid"
        />
      </div>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Report);
