import React, { useState } from 'react';

import { formatReportTimeShort } from '../../../utils/dates';
import { getReportsTotals } from '../../../utils/report.util';

import { IReport, IReportsGroupByDate } from '../../../types/IReport';
import { IUser } from '../../../types/IUser';
import { IProfile } from '../../../types/IProfile';

import { UserRow } from './UserRow';

import styles from './Table.module.scss';

interface IProps {
  report: IReportsGroupByDate;
  users: IUser[];
  onReportClick: (report: IReport) => void;
  onUserMouseOver: (user: IUser | IProfile) => void;
  profile: IProfile;
}

export const AdminRow: React.FC<IProps> = ({report, profile, users, onReportClick, onUserMouseOver}) => {
  const [expand, setExpand] = useState<boolean>(false);
  const totals = getReportsTotals(report.reports);

  const onClickExpand = () => setExpand(!expand);

  const onClickReport = (report: IReport) => () => onReportClick(report);

  const renderReports = () => report.reports.map( report => (
    <UserRow
      key={report._id}
      report={report}
      profile={profile}
      users={users}
      onReportClick={onClickReport(report)}
      onUserMouseOver={onUserMouseOver}
      isExpandable={true}
    />
  ));

  return (
    <div className={`${styles.row} ${styles.rowExpand} ${styles.value}`}>
      <p className={styles.column} onClick={onClickExpand}>
        <button className={`${styles.expand} ${expand ? styles.active : ''}`} />{formatReportTimeShort(report.date)}
      </p>
      <p className={styles.column}>{totals.doorsKnocked || 0}</p>
      <p className={styles.column}>{totals.hoursKnocking || 0}</p>
      <p className={styles.column}>{totals.leadsActual || 0}</p>
      <p className={styles.column}>{totals.evaluations || 0}</p>
      <p className={styles.column}>{totals.insuranceLooks || 0}</p>
      <p className={styles.column}>{totals.adjusterMeetings || 0}</p>
      <p className={styles.column}>{totals.approvals || 0}</p>
      <p className={styles.column}>{totals.totalColorMeetings || 0}</p>
      <p className={styles.column}>{totals.projectsTurnedIn || 0}</p>
      <p className={styles.column}>{totals.dollarsBooked || 0}</p>
      <p className={styles.column}>{totals.dollarsClosed || 0}</p>
      <div className={`${styles.expandSection} ${expand ? styles.active : ''}`}>
        {renderReports()}
      </div>
    </div>
  );
};
