import React from 'react';

import { RatingTableRow } from './RatingTableRow';

import { IRating } from '../../../types/IRating';

import styles from './RatingAdmin.module.scss';

interface IProps {
  ratings: IRating[];
  reportContent: {[name: string]: string};
}

const renderRows = (ratings: IRating[], reportContent: {[name: string]: string}) => ratings.map( (rating, index) =>
  <RatingTableRow reportContent={reportContent} key={rating._id} index={index} rating={rating} />);

export const RatingAdmin: React.FC<IProps> = ({ratings, reportContent}) => (
  <div className={styles.rating}>
    <p className={styles.title}>Users rating</p>
    <div className={styles.table}>
      {ratings.length ? (
        <>
          <div className={styles.rowHeader}>
            <p className={styles.column}>
              #
            </p>
            <p className={styles.column}>
              Username
            </p>
            <p className={styles.column}>
              Name
            </p>
            <p className={`${styles.column} ${styles.biggest}`}>
              Rate Day Average
            </p>
            <p className={`${styles.column} ${styles.biggest}`}>
              Amount Time Average
            </p>
            <p className={styles.column}>
              Yes/No/Total
            </p>
            <p className={`${styles.column} ${styles.last}`}>
              Rating
            </p>
          </div>
          {renderRows(ratings, reportContent)}
        </>
      ) : <p className={styles.noData}>We do not have rating yet</p>}
    </div>
  </div>
);
