import React, { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Dispatch } from 'redux';

import { PICKER } from '../../../constants/datepicker.config';
import { FILTER_QUICK_PERIODS } from '../../../constants/Filters';
import { REPORT_EXPORT_TYPES } from '../../../constants/report';

import { IStore } from '../../../types/IStore';
import { IProfile } from '../../../types/IProfile';
import { IFilters } from '../../../types/IFilters';
import { IUser } from '../../../types/IUser';
import { ICompany } from '../../../types/ICompany';

import { filtersActions } from '../../../sagas/filters.saga';

import { isReady } from '../../../utils/valueState';
import { isAdmin } from '../../../utils/user.util';
import moment from '../../../utils/moment';
import { getQuickFilterDate } from '../../../utils/dates';
import { exportReport } from '../../../utils/report.util';

import styles from './Filters.module.scss';

interface IPropsFromStore {
  profile: IProfile;
  users: IUser[];
  companies: ICompany[];
}

interface IDispatchProps {
  setFilters: (payload: IFilters) => void;
}

const mapStateToProps = ({ profile, users, companies}: IStore): IPropsFromStore => ({
  profile,
  users,
  companies,
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  setFilters: (payload: IFilters) =>
    dispatch(filtersActions.set.update(payload)),
});

type IProps = IPropsFromStore & IDispatchProps;

interface IState {
  increased: boolean;
  from: string | number | null;
  to: string | number | null;
  companiesField: string;
  usersField: string;
  selectedCompanies: ICompany[];
  usersIDs: string[];
  companiesIDs: string[];
  isShowSelected: boolean;
  companies: ICompany[];
  pdfExportEnabled: boolean | null;
}

class Filters extends Component<IProps, IState> {
  public state: Readonly<IState> = {
    increased: false,
    from: '',
    to: '',
    companiesField: '',
    usersField: '',
    selectedCompanies: [],
    usersIDs: [],
    companiesIDs: [],
    isShowSelected: false,
    companies: [],
    pdfExportEnabled: null,
  };
  private companiesParticipantsAdded: boolean = false;

  public componentDidMount(): void {
    const { companies, users, profile } = this.props;

    if ( isReady(companies) && isReady(users) ) {
      this.calcCompaniesParticipants();
    }
    if ( isReady(profile) ) {
      this.setState({
        pdfExportEnabled: !isAdmin(profile),
      })
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { companies, users, profile } = this.props;

    if ( isReady(companies) && isReady(users) && !this.companiesParticipantsAdded ) {
      this.calcCompaniesParticipants();
    }
    if ( isReady(profile) && this.state.pdfExportEnabled === null ) {
      this.setState({
        pdfExportEnabled: !isAdmin(profile),
      })
    }
  }

  private calcCompaniesParticipants = () => {
    const { users } = this.props;
    const companies = [...this.props.companies];

    companies.forEach( company => {
      company.participants = users.filter( user => user.company === company._id).map( ({_id}) => _id);
    });

    this.setState({
      companies,
    }, () => this.companiesParticipantsAdded = true);
  };

  private increasedToggle = () =>
    this.setState({
      increased: !this.state.increased
    });

  private setFromDate = date =>
    this.setState({
      from: moment.utc(date).valueOf(),
    });

  private setToDate = date =>
    this.setState({
      to: moment.utc(date).valueOf(),
    });

  private onClickApply = () => {
    const { from, to, usersIDs } = this.state;

    this.props.setFilters({
      from: from
        ? moment(from).format('YYYY-MM-DD')
        : null,
      to: to
        ? moment(to).format('YYYY-MM-DD')
        : null,
      users: usersIDs
    });
  };

  private onChangeSearchField = (e: SyntheticEvent, field: string) => {
    const { target } = e;

    this.setState(
      // @ts-ignore
      {
        [field]: (target as HTMLInputElement).value as string
      }
    );
  };

  private resetSearchField = (field: string) => () =>
    this.setState(
      // @ts-ignore
      {
        [field]: ''
      }
    );

  private onUserSelect = (user: IUser) => () => {
    const usersIDs: string[] = [...this.state.usersIDs];
    const userIDIndex = usersIDs.findIndex(id => id === user._id);

    userIDIndex >= 0
      ? usersIDs.splice(userIDIndex, 1)
      : usersIDs.push(user._id);

    this.setState({
      usersIDs,
    }, () => {
      const companiesIDs: string[] = [...this.state.companiesIDs];

      this.state.companies.forEach( company => {
        // @ts-ignore
        const isUsersFromCompany = company.participants.every(participant => this.state.usersIDs.includes(participant));
        const companyIDIndex = companiesIDs.findIndex(id => id === company._id);

        if ( isUsersFromCompany && companyIDIndex < 0 ) {
          companiesIDs.push(company._id);
        } else if ( !isUsersFromCompany && companyIDIndex >= 0 ) {
          companiesIDs.splice(companyIDIndex, 1);
        }
      });

      this.setState({
        companiesIDs,
        pdfExportEnabled: this.state.usersIDs.length === 1,
      })
    });
  };

  private onCompanySelect = (company: ICompany) => () => {
    const companiesIDs: string[] = [...this.state.companiesIDs];
    const companyIDIndex = companiesIDs.findIndex(id => id === company._id);

    companyIDIndex >= 0
      ? companiesIDs.splice(companyIDIndex, 1)
      : companiesIDs.push(company._id);

    // @ts-ignore
    const notSelectedUsers = company.participants.filter((participant: string) => !this.state.usersIDs.some(id => participant === id));

    this.setState({
      companiesIDs,
      usersIDs: [
        ...this.state.usersIDs,
        ...notSelectedUsers,
      ]
    })
  };

  private checkIsUserActive = (user: IUser): boolean =>
    this.state.usersIDs.some(id => id === user._id);

  private checkIsCompanyActive = (company: ICompany): boolean =>
    this.state.companiesIDs.some(id => id === company._id);

  private setQuickFilter = (period: string) => () => {
    const { LAST_WEEK, LAST_MONTH, LAST_YEAR } = FILTER_QUICK_PERIODS;

    if ( period === LAST_WEEK.name ) {
      this.setState({
        from: getQuickFilterDate(LAST_WEEK),
      })
    } else if ( period === LAST_MONTH.name ) {
      this.setState({
        from: getQuickFilterDate(LAST_MONTH),
      })
    } else {
      this.setState({
        from: getQuickFilterDate(LAST_YEAR),
      })
    }
  };

  private onChangeShowSelected = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;

    this.setState({
      isShowSelected: target?.checked || false,
    })
  };

  private exportReports = (type: string) => () => {
    const userID = this.state.usersIDs[0];

    exportReport(type, userID).then( ({data}) => window.open(data, '_self'));
  };

  private renderUsers = (users: IUser[], filterField: string) =>
    users
      .filter(user => {
        const userName =
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.username || user.email;

        if ( !this.state.isShowSelected ) {
          return userName.match(filterField);
        } else {
          const selectedCompanies = this.state.companies.filter( company => this.state.companiesIDs.some( id => id === company._id));

          // @ts-ignore
          return userName.match(filterField) && selectedCompanies.some(company => company.participants.includes(user._id));
        }
      })
      .map(user => (
        <li
          key={user._id}
          onClick={this.onUserSelect(user)}
          className={this.checkIsUserActive(user) ? styles.active : ''}
        >
          {user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.username || user.email}
        </li>
      ));

  private renderCompanies = (companies: ICompany[], filterField: string) =>
    companies
      .filter(company => company.name.match(filterField))
      .map(company => (
        <li
          key={company._id}
          onClick={this.onCompanySelect(company)}
          className={this.checkIsCompanyActive(company) ? styles.active : ''}
        >
          {company.name}
        </li>
      ));

  public render() {
    const { increased, from, to, companiesField, usersField, isShowSelected, companies, pdfExportEnabled } = this.state;
    const { profile, users } = this.props;

    return (
      <div className={`${styles.filters} ${increased ? styles.increased : ''}`}>
        <div className={styles.header}>
          <div className={styles.timeRange}>
            <div className={`${styles.pickerWrapper} picker--custom ${!from ? 'empty' : ''}`}>
              <DatePicker
                {...PICKER}
                selected={from}
                className={`${styles.timeRangePicker} ${!from ? styles.empty : ''}`}
                onChange={this.setFromDate}
                placeholderText="--/--/----"
                maxDate={to || Date.now()}
                isClearable={true}
              />
            </div>
            <span>to</span>
            <div className={`${styles.pickerWrapper} picker--custom ${!to ? 'empty' : ''}`}>
              <DatePicker
                {...PICKER}
                selected={to}
                className={`${styles.timeRangePicker} ${!to ? styles.empty : ''}`}
                onChange={this.setToDate}
                placeholderText="--/--/----"
                minDate={from}
                isClearable={true}
              />
            </div>
            <ul className={styles.quickFiltersList}>
              <li>
                <button onClick={this.setQuickFilter(FILTER_QUICK_PERIODS.LAST_WEEK.name)}>last week</button>
              </li>
              <li>
                <button onClick={this.setQuickFilter(FILTER_QUICK_PERIODS.LAST_MONTH.name)}>last month</button>
              </li>
              <li>
                <button onClick={this.setQuickFilter(FILTER_QUICK_PERIODS.LAST_YEAR.name)}>last year</button>
              </li>
            </ul>
            {isAdmin(profile) ? (
              <>
                <button
                  className={styles.moreFiltersBtn}
                  onClick={this.increasedToggle}
                >
                  More filters
                </button>
                <button
                  className={styles.applyBtn}
                  onClick={this.onClickApply}
                >
                  Apply
                </button>
              </>
            ) : (
              <button
                className={styles.applyBtn}
                onClick={this.onClickApply}
              >
                Apply
              </button>
            )}
          </div>
          <div className={styles.export}>
            {pdfExportEnabled && (
              <button className={styles.exportBtn} onClick={this.exportReports(REPORT_EXPORT_TYPES.PDF.name)}>PDF</button>
            )}
            <button className={styles.exportBtn} onClick={this.exportReports(REPORT_EXPORT_TYPES.CSV.name)}>XLSX</button>
          </div>
        </div>
        {isAdmin(profile) && (
          <>
            <div className={styles.content}>
              <div className={styles.area}>
                <div className={styles.areaSearch}>
                  <label htmlFor="companies" className={styles.areaSearchTitle}>
                    Companies
                  </label>
                  <input
                    id="companies"
                    type="text"
                    value={companiesField}
                    className={styles.areaSearchField}
                    onChange={e =>
                      this.onChangeSearchField(e, 'companiesField')
                    }
                  />
                  <button
                    disabled={!companiesField.length}
                    onClick={this.resetSearchField('companiesField')}
                    className={styles.areaSearchReset}
                  >
                    Reset
                  </button>
                </div>
                <ul className={styles.areaList}>
                  {this.renderCompanies(companies, companiesField)}
                </ul>
              </div>
              <div className={styles.area}>
                <div className={styles.areaSearch}>
                  <label htmlFor="users" className={styles.areaSearchTitle}>
                    Users
                  </label>
                  <input
                    id="users"
                    type="text"
                    value={usersField}
                    className={styles.areaSearchField}
                    onChange={e => this.onChangeSearchField(e, 'usersField')}
                  />
                  <button
                    disabled={!usersField.length}
                    onClick={this.resetSearchField('usersField')}
                    className={styles.areaSearchReset}
                  >
                    Reset
                  </button>
                </div>
                <ul className={`${styles.areaList} ${styles.areaListCheckbox}`}>
                  {isReady(users) && this.renderUsers(users, usersField)}
                </ul>
                {isReady(companies) && (
                  <label
                    className={`${styles.fieldCheckbox} ${
                      !companies.length ? styles.disabled : ''
                    }`}
                  >
                    <input checked={isShowSelected} disabled={!companies.length} type="checkbox" onChange={this.onChangeShowSelected} />
                    <span className={styles.checkerCheckbox} />
                    <p className={styles.fieldTitle}>
                      Show users only from selected companies
                    </p>
                  </label>
                )}
              </div>
            </div>
            <button
              className={`${styles.applyBtn} ${styles.applyBtnIncreased}`}
              onClick={this.onClickApply}
            >
              Apply
            </button>
          </>
        )}
      </div>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Filters);
