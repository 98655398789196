import React from 'react';

import { IUser } from '../../../types/IUser';
import { IReport } from '../../../types/IReport';
import { IProfile } from '../../../types/IProfile';

import { isReady } from '../../../utils/valueState';
import { formatReportTimeShort } from '../../../utils/dates';
import { isAdmin } from '../../../utils/user.util';

import styles from './Table.module.scss';

interface IProps {
  users: IUser[];
  report: IReport;
  onReportClick: (report: IReport) => void;
  onUserMouseOver: (user: IUser | IProfile) => void;
  profile: IProfile;
  isExpandable?: boolean;
}

export const UserRow: React.FC<IProps> = ({users, report, onReportClick, onUserMouseOver, profile, isExpandable}) => {
  const user =
    (isReady(users) && users.find(user => user._id === report.creator)) || profile;

  return (
    <div className={`${styles.row} ${styles.value}`} onClick={() => onReportClick(report)}>
      <p className={styles.column}>
        {!isExpandable && formatReportTimeShort(report.createdAt)}
        {!isExpandable && isAdmin(profile) && user && (
          <span
            className={styles.user}
            data-tip="user"
            onMouseOver={() => onUserMouseOver(user)}
          >
              {user.profileImg ? (
                <img src={user.profileImg} alt={user.profileImg} />
              ) : (
                <span>
                  {user.firstName
                    ? user.firstName.charAt(0)
                    : user.username
                      ? user.username.charAt(0)
                      : user.email.charAt(0)}
                </span>
              )}
            </span>
        )}
        {isExpandable && user && (
          <span className={styles.userMail} data-tip="user" onMouseOver={() => onUserMouseOver(user)}>{user.email}</span>
        )}
      </p>
      <p className={styles.column}>{report.doorsKnocked || 0}</p>
      <p className={styles.column}>{report.hoursKnocking || 0}</p>
      <p className={styles.column}>{report.leadsActual || 0}</p>
      <p className={styles.column}>{report.evaluations || 0}</p>
      <p className={styles.column}>{report.insuranceLooks || 0}</p>
      <p className={styles.column}>{report.adjusterMeetings || 0}</p>
      <p className={styles.column}>{report.approvals || 0}</p>
      <p className={styles.column}>{report.totalColorMeetings || 0}</p>
      <p className={styles.column}>{report.projectsTurnedIn || 0}</p>
      <p className={styles.column}>{report.dollarsBooked || 0}</p>
      <p className={styles.column}>{report.dollarsClosed || 0}</p>
    </div>
  );
};
