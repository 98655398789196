import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import {IReport, IReportsGroupByDate} from '../types/IReport';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getStore } from '../store/store';
import { assignFiltersParams } from '../utils/filters.util';
import { isReady } from '../utils/valueState';

import { paginationActions } from './pagination.saga';

const resource = 'REPORTS_GROUP_BY_DATE';
const actions = ['get', 'update', 'delete'];
type reducerType = IReportsGroupByDate[];

export const groupedReportsTypes = createActionTypes(resource, actions);
export const groupedReportsActions = createActions<reducerType>(resource, actions);
export const groupedReportsReducer = createReducers<reducerType>(resource, actions);

function* getReports() {
  try {
    const { pagination, filters } = getStore();

    const query = filters
      ? assignFiltersParams(filters, `?page=${pagination.activePage}`)
      : `?page=${pagination.activePage}`;

    const { reports, pages, prevPage, nextPage } = yield call(http.get, API_ENDPOINTS.REPORTS_GROUPED, undefined, undefined, query);

    yield put(groupedReportsActions.get.success(reports));
    yield put(paginationActions.set.update({
      activePage: pagination.activePage,
      pages,
      prevPage,
      nextPage,
    }))
  } catch (err) {
    yield put(groupedReportsActions.get.fail(err));
  }
}

function* updateReport({payload}: {payload: IReport}) {
  const { groupedReports } = getStore();

  if ( isReady(groupedReports) ) {
    const reportsGroup = groupedReports.find(({reports}) => reports.find(({_id}) => _id === payload.reportID));

    if ( reportsGroup ) {
      const reportIndex = reportsGroup.reports.findIndex( ({_id}) => _id === payload.reportID);
      const report = {
        ...reportsGroup.reports[reportIndex],
        ...payload,
      };

      reportsGroup.reports.splice(reportIndex, 1, report);

      yield put(groupedReportsActions.update.success(groupedReports));
    }
  }
}

function* deleteReport({payload}: {payload: string}) {
  const { groupedReports } = getStore();

  if ( isReady(groupedReports) ) {
    const reportsGroupIndex = groupedReports.findIndex(({reports}) => reports.find(({_id}) => _id === payload));

    if ( reportsGroupIndex >= 0 ) {
      const reportIndex = groupedReports[reportsGroupIndex].reports.findIndex( ({_id}) => _id === payload);

      groupedReports[reportsGroupIndex].reports.splice(reportIndex, 1);

      if ( groupedReports[reportsGroupIndex].reports.length === 0 ) {
        groupedReports.splice(reportsGroupIndex, 1);
      }

      yield put(groupedReportsActions.delete.success(groupedReports));
    }
  }
}

export function* groupedReportsSaga() {
  yield takeEvery(groupedReportsTypes.get.load, getReports);
  yield takeEvery(groupedReportsTypes.update.update, updateReport);
  yield takeEvery(groupedReportsTypes.delete.update, deleteReport);
}
