import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ICompany } from '../../../../types/ICompany';
import { IStore } from '../../../../types/IStore';

import { ADMIN_TIME_FORMAT } from '../../../../constants/admin';

import moment from '../../../../utils/moment';
import { isReady } from '../../../../utils/valueState';

import { selectedCompanyActions } from '../../../../sagas/selectedCompany.saga';

import styles from './CompaniesTab.module.scss';
import {IUser} from '../../../../types/IUser';

interface IPropsFromStore {
  companies: ICompany[];
  users: IUser[];
}

interface IDispatchProps {
  setSelectedCompany: (payload: ICompany) => void;
}

const mapStateToProps = ({ companies, users }: IStore): IPropsFromStore => ({
  companies,
  users,
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  setSelectedCompany: (payload: ICompany) => dispatch(selectedCompanyActions.set.update(payload)),
});

type IProps = IPropsFromStore & IDispatchProps;

class CompaniesTab extends Component<IProps> {

  private onClickCompany = (company: ICompany) => () => this.props.setSelectedCompany(company);

  private renderCompanies = () => this.props.companies.map( (company) => {
    const companyUsers = this.props.users.filter( user => user.company === company._id);

    return (
      <div key={company._id} className={styles.tableRow} onClick={this.onClickCompany(company)}>
        <p className={styles.tableItem}>{company.name}</p>
        <p className={styles.tableItem}>{companyUsers.length}</p>
        <p className={styles.tableItem}>{moment(company.createdAt).format(ADMIN_TIME_FORMAT)}</p>
        {company.logo ? (
          <div className={styles.tableItem}>
            <div className={styles.logo}>
              <img src={company.logo} alt={company.name} />
            </div>
          </div>
        ) : (
          <p className={styles.tableItem}>No logo</p>
        )}
      </div>
    )
  });

  public render() {
    return isReady(this.props.companies) ? (
      <div className={styles.table}>
        {this.props.companies.length ? (
          <>
            <div className={`${styles.tableRow} ${styles.tableHeader}`}>
              <p className={styles.tableItem}>Name</p>
              <p className={styles.tableItem}>Users</p>
              <p className={styles.tableItem}>Created At</p>
              <p className={styles.tableItem}>Logo</p>
            </div>
            {this.renderCompanies()}
          </>
        ) : <p className={styles.noContent}>No companies</p>}
      </div>
    ) : null;
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps,
)(CompaniesTab);
