import { ChartOptions } from 'chart.js';

interface IChartTypes {
  PIE: number;
  BAR: number;
  LINE: number;
  BAR_DASHBOARD: number;
}

export const CHART_TYPES: IChartTypes = {
  PIE: 1,
  BAR: 2,
  LINE: 3,
  BAR_DASHBOARD: 4,
};

export const PIE_CHART_CONFIG: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
    position: 'right',
    labels: {
      fontFamily: 'Roboto',
      fontColor: '#0356A7',
    }
  },
  legendCallback: (chart: Chart): string => {
    const { data } = chart;
    let legendHTML = '<ul class="chart-legend">';

    try {
      if ( data ) {
        data.labels?.forEach( (label, index) => {
          // @ts-ignore
          legendHTML += `<li class="chart-legend__item" style="border-color: ${data?.datasets[0]?.backgroundColor[index]}">`
            + label + '</li>';
        });
      }
    } catch (err) {
      console.error(err);
    }

    return legendHTML + '</ul>';
  },
};

export const HORIZONTAL_BAR_CHART_CONFIG: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
    position: 'right',
    labels: {
      boxWidth: 0,
      fontFamily: 'Roboto',
      fontColor: '#0356A7',
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        }
      }
    ],
    xAxes: [
      {
        display: false,
        ticks: {
          beginAtZero: true,
        }
      }
    ]
  },
  tooltips: {
    intersect: false,
  },
  legendCallback: (chart: Chart): string => {
    const { data } = chart;
    let legendHTML = '<ul class="chart-legend">';

    try {
      data.datasets?.forEach( ({label}) => {
        legendHTML += '<li class="chart-legend__item">' + label + '</li>';
      });
    } catch (err) {
      console.error(err);
    }

    return legendHTML + '</ul>';
  }
};

export const LINE_CHART_CONFIG: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
    position: 'right',
    labels: {
      fontFamily: 'Roboto',
      fontColor: '#0356A7',
    }
  },
  layout: {
    padding: {
      right: 5,
    },
  },
  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
          beginAtZero: true,
        },
      },
    ],
  },
  elements: {
    line: {
      cubicInterpolationMode: 'monotone',
      backgroundColor: 'transparent',
      borderWidth: 1,
    },
  },
  tooltips: {
    intersect: false,
    enabled: false,
  },
  legendCallback: (chart: Chart): string => {
    const { data } = chart;
    let legendHTML = '<ul class="chart-legend">';

    try {
      data.datasets?.forEach( ({label, borderColor}) => {
        legendHTML += `<li class="chart-legend__item" style="border-color: ${borderColor}">` + label + '</li>';
      });
    } catch (err) {
      console.error(err);
    }

    return legendHTML + '</ul>';
  },
};
